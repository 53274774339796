<template>
  <div class="relative flex items-center justify-center w-full h-full">
    <div class="relative inline-block w-12 h-12">
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="border-2 border-black border-solid rounded-full opacity-100 animate-ripple" />
      </div>
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="border-2 border-black border-solid rounded-full opacity-100 animate-ripple-delay" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
