<template>
  <section data-testid="image-slider" :class="{ 'w-screen h-screen': !inline }">
    <h2 v-if="title && inline" class="block px-4 pb-4 typo-artikel-sub">
      {{ title }}
    </h2>
    <div
      class="relative w-full h-full"
      :class="{ 'aspect-square md:aspect-video': inline }"
    >
      <slot v-if="inline" name="icon-top-right">
        <Fullscreen
          class="absolute z-20 text-white cursor-pointer top-8 right-8"
          @click="emit('fullscreenClicked', images)"
        />
      </slot>

      <client-only>
        <swiper-container
          class="h-full overflow-hidden bg-black"
          :class="{ 'md:rounded-lg': inline }"
          :modules="[Navigation]"
          :navigation="{
            prevEl: '.np-swiper-button-prev',
            nextEl: '.np-swiper-button-next',
          }"
          :initial-slide="initialSlide"
          :loop="true"
          @swiperslidechange="onSlideChange"
        >
          <slot name="slides">
            <template v-if="images.length > 0">
              <swiper-slide
                v-for="(image, index) in images"
                :key="index"
                class="swiper-slide"
              >
                <figure class="inline-flex flex-col w-full h-full">
                  <img
                    loading="lazy"
                    class="object-contain w-full h-full"
                    :src="image.src"
                    :alt="image.alt"
                  />
                  <div
                    class="absolute z-20 px-4 md:w-1/2 md:left-1/4"
                    :class="{
                      'bottom-6 w-4/5 text-left md:text-center': inline,
                      'bottom-16 text-center md:bottom-8': !inline,
                    }"
                  >
                    <figcaption
                      v-if="caption"
                      class="text-white typo-meta-1"
                      v-html="caption"
                    />
                  </div>
                </figure>
              </swiper-slide>
            </template>
          </slot>
        </swiper-container>
      </client-only>
      <div
        class="hidden shadow md:block"
        :class="{ 'md:after:rounded-lg md:before:rounded-lg': inline }"
      />
      <ArrowRight class="text-white np-swiper-button-prev" />
      <ArrowRight class="text-white np-swiper-button-next" />
      <span class="absolute z-10 text-white bottom-8 right-8 typo-meta-1-bold">
        {{ activeIndex }} / {{ slidesTotal }}
      </span>
    </div>
  </section>
</template>

<script setup lang="ts">
import { register } from 'swiper/element/bundle'
import { Navigation } from 'swiper/modules'
import { computed, ref } from 'vue'
import ArrowRight from '../../assets/svg/ArrowRight.svg'
import Fullscreen from '../../assets/svg/Fullscreen.svg'

interface GalleryImage {
  src: string
  sizes?: string
  srcset?: ImageSrcSet[]
  alt?: string
  caption?: string
}

const props = withDefaults(
  defineProps<{
    title?: string
    images?: GalleryImage[]
    inline?: boolean
    initialSlide?: number
    slidesTotal?: number
  }>(),
  {
    images: () => [],
    inline: true,
    initialSlide: 0,
    slidesTotal: 0,
  },
)

const emit = defineEmits<{
  (event: 'fullscreenClicked', images: Array<GalleryImage>): void
  (event: 'slideChanged', activeIndex: number): void
}>()

register()

const activeIndex = ref(1)

const slidesTotal = computed(() => {
  if (props.slidesTotal) {
    return props.slidesTotal
  }

  return props.images.length ?? 0
})

const caption = computed(() => props.images[activeIndex.value - 1].caption)

function onSlideChange(e: any) {
  activeIndex.value = e.detail[0].realIndex + 1
  emit('slideChanged', activeIndex.value)
}
</script>

<style>
.np-swiper-button-next,
.np-swiper-button-prev {
  @apply absolute right-0 z-20 items-center w-9 top-1/2 -translate-y-1/2 hidden md:flex;
}

.np-swiper-button-prev {
  @apply left-6 rotate-180;
}

.np-swiper-button-next {
  @apply right-6;
}
.swiper-slide::after {
  @apply content-[''] absolute left-0 bottom-0 h-full w-full z-10;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0) 27.63%
  );
}

.shadow::before,
.shadow::after {
  @apply content-[''] absolute top-0 w-20 z-10 h-full;
}
.shadow::before {
  @apply left-0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.shadow::after {
  @apply right-0;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
</style>
