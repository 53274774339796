<template>
  <dialog
    ref="dialog"
    class="backdrop:bg-black/50 flex flex-col w-11/12 max-w-xl max-h-[calc(100vh-4rem)] gap-6  rounded-lg md:w-1/2"
  >
    <div v-if="isOpen" class="p-6 bg-white">
      <component :is="modal" @close="close" />
    </div>
  </dialog>
</template>

<script setup lang="ts">
const { modal, closeModal, setDialog, isOpen } = useModal()

const dialog = useTemplateRef<HTMLDialogElement>('dialog')
onMounted(() => {
  setDialog(dialog)
})

function close() {
  closeModal()
}
</script>
