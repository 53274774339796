<template>
  <button
    title="X"
    type="button"
    class="flex flex-col items-center gap-2"
    data-testid="x"
    @click="share"
  >
    <XIcon />
    <span class="typo-meta-1">Auf X teilen</span>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import XIcon from '../../../assets/svg/X.svg'

const props = defineProps<{
  url: string
  text: string
}>()

const emit = defineEmits<{
  (event: 'buttonClicked', title: string): void
}>()

const encodedUrl = computed(() => encodeURI(props.url))
const encodedText = computed(() => encodeURI(props.text))

function share() {
  window.open(
    `https://twitter.com/intent/tweet?text=${encodedText.value}&url=${encodedUrl.value}`,
    '_blank',
    'width=500,height=400',
  )

  emit('buttonClicked', 'facebook')
}
</script>
