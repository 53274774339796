import type { RouterConfig } from '@nuxt/schema'

export default {
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: _routes => [
    ..._routes,
    {
      name: 'themen-page-page',
      path: '/themen/page/:page',
      component: () => import('~/pages/themen/[[_]]/[[page]].vue'),
    },
  ],
} satisfies RouterConfig
