import { useSSO as useLibSSO } from '@russmedia-frontend/np-common'

export default defineNuxtPlugin({
  name: 'auth',
  parallel: true,
  async setup() {
    const auth = useAuth()

    const sessionCookie = useCookie(ssoSessionCookieName)
    const evoSSOCookie = useCookie(ssoCookieName)

    if (!sessionCookie.value || !evoSSOCookie.value) {
      return
    }

    const { hash, token } = auth.getHashTokenFromCookie()

    if (!hash || !token) {
      return
    }

    try {
      await auth.autologin(hash, token)

      if (sessionCookie.value && evoSSOCookie.value) {
        useLibSSO().setSSOCookie(sessionCookie.value, evoSSOCookie.value) // does not really set a cookie, but the state
      }
    }
    catch (error) {
      console.error('autologin failed', error)
    }
  },
})
