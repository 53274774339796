<template>
  <div data-testid="responsive-picture">
    <picture :class="additionalClass">
      <source
        v-if="picture?.source && picture.source.length > 0"
        :media="picture.source[0].media"
        :sizes="picture.source[0].sizes"
        :srcset="filterSrcSets(picture.source[0].srcset)"
      />

      <img
        v-if="picture?.img"
        class="w-full rounded"
        :src="picture.img.src"
        :sizes="picture.img.sizes ? picture.img.sizes : ''"
        :srcset="filterSrcSets(picture.img.srcset)"
        :alt="picture.img.alt ? picture.img.alt : ''"
        :title="picture.img.alt ? picture.img.alt : ''"
      />
    </picture>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    picture: PictureItem
    additionalClass?: string
  }>(),
  {
    additionalClass: '',
  },
)

function filterSrcSets(data?: ImageSrcSet[]): string {
  if (!data || data.length === 0) {
    return ''
  }

  if (typeof data === 'string') {
    return data
  }

  return data
    .map((srcset: ImageSrcSet) => {
      if (!srcset.src || !srcset.w) {
        return ''
      }
      return `${srcset.src} ${srcset.w}`
    })
    .join(', ')
}
</script>
