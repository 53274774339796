<template>
  <a
    :href="link"
    class="flex flex-col items-center gap-2"
    data-testid="email"
    @click="emit('buttonClicked', 'email')"
  >
    <FacebookIcon />
    <span class="text-center typo-meta-1">Per Email teilen</span>
  </a>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import FacebookIcon from '../../../assets/svg/Email.svg'

const props = defineProps<{
  url: string
  text: string
}>()

const emit = defineEmits<{
  (event: 'buttonClicked', title: string): void
}>()

const link = computed(() => {
  const body = `Artikel auf VOL.AT lesen: ${props.url}`
  return `mailto:?subject=${props.text}&body=${body}`
})
</script>
