<template>
  <button
    title="Teilen"
    type="button"
    class="flex flex-col items-center gap-2"
    data-testid="universal"
    @click="share"
  >
    <ShareIcon />
    <span class="typo-meta-1">Teilen</span>
  </button>
</template>

<script setup lang="ts">
import ShareIcon from '../../../assets/svg/Share.svg'

const props = defineProps<{
  url: string
  text: string
}>()

const emit = defineEmits<{
  (event: 'buttonClicked', title: string): void
}>()

function share() {
  try {
    navigator.share({
      url: props.url,
      text: props.text,
    })

    emit('buttonClicked', 'share')
  }
  catch {}
}
</script>
