interface ExtWindow extends Window {
  dataLayer: any
}

declare let window: ExtWindow

export function useTracking() {
  const clicktracking = (element: string, element_type: string, action: string = 'next', click_url: string = '', element_text: string = '', element_position: string = '') => {
    if (!window.dataLayer) {
      return
    }

    const trackingEvent: any = {
      click_element_type: element_type,
      click_element_name: element,
      click_action: action,
      event: 'clicktracking',
    }

    if (click_url) {
      trackingEvent.click_url = click_url
    }

    if (element_text) {
      trackingEvent.click_element_text = element_text
    }

    if (element_position) {
      trackingEvent.click_element_position = element_position
    }

    window.dataLayer.push(trackingEvent)
  }

  return { clicktracking }
}
