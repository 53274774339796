<template>
  <div class="p-4 bg-main-200">
    <table class="w-full text-xs border-separate table-fixed border-spacing-y-1 snow-report-widget">
      <thead>
        <tr>
          <th class="text-left" colspan="2">Skigebiet</th>
          <SnowReportSortableEntry
            :is-currently-sorted="sortType === 'base'"
            @sort-order-update="sort('base', $event)"
          >
            Tal
          </SnowReportSortableEntry>
          <SnowReportSortableEntry
            :is-currently-sorted="sortType === 'top'"
            @sort-order-update="sort('top', $event)"
          >
            Berg
          </SnowReportSortableEntry>
          <SnowReportSortableEntry
            :is-currently-sorted="sortType === 'lifts'"
            @sort-order-update="sort('lifts', $event)"
          >
            offene Lifte
          </SnowReportSortableEntry>
        </tr>
      </thead>
      <tbody>
        <SnowReportEntry
          v-for="item in sortedItems"
          :key="item.resort"
          :item="item"
        />
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import SnowReportEntry from './SnowReportEntry.vue'
import SnowReportSortableEntry from './SnowReportSortableEntry.vue'

const props = defineProps<{
  items: SnowReport[]
}>()

const sortOrder = ref<SnowReportSortOrder>('none')
const sortType = ref<SnowReportSortType>('base')

const sortedItems = computed(() => {
  if (sortOrder.value === 'none') {
    return props.items
  }

  return props.items.slice().sort((a, b) => {
    let aValue: number = 0
    let bValue: number = 0
    if (sortType.value === 'lifts') {
      aValue = a.openLifts
      bValue = b.openLifts
    }
    else {
      aValue = a.depths[sortType.value]
      bValue = b.depths[sortType.value]
    }

    if (sortOrder.value === 'ascending') {
      return aValue - bValue
    }

    return bValue - aValue
  })
})

function sort(type: SnowReportSortType, order: SnowReportSortOrder) {
  sortType.value = type
  sortOrder.value = order
}
</script>

<style>
.snow-report-widget {
  th {
    @apply font-normal;
  }
}
</style>
