<template>
  <th class="cursor-pointer" @click="() => callNext()">
    <div class="flex items-center justify-center gap-1">
      <span>
        <svg
          class="w-1.5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          :class="{ 'text-black': ascending, 'text-black/60': descending }"
          viewBox="0 0 7 5"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1.111"
            d="M5.667 3.556 3.64 1.53a.278.278 0 0 0-.393 0L1.222 3.556"
          />
        </svg>
        <svg
          class="w-1.5 rotate-180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="{ 'text-black': descending, 'text-black/60': ascending }"
          viewBox="0 0 7 5"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1.111"
            d="M5.667 3.556 3.64 1.53a.278.278 0 0 0-.393 0L1.222 3.556"
          />
        </svg>
      </span>
      <slot />
    </div>
  </th>
</template>

<script setup lang="ts">
import { useCycleList } from '@vueuse/core'
import { computed, watchEffect } from 'vue'

const props = defineProps<{ isCurrentlySorted: boolean }>()
const emit = defineEmits<{
  sortOrderUpdate: [sortOrder: SnowReportSortOrder]
}>()

const { state, next, go } = useCycleList<SnowReportSortOrder>([
  'none',
  'ascending',
  'descending',
])

function callNext() {
  const newSortOrder = next()

  emit('sortOrderUpdate', newSortOrder)
}

watchEffect(() => {
  if (!props.isCurrentlySorted) {
    go(0)
  }
})

const ascending = computed(
  () => props.isCurrentlySorted && state.value === 'ascending',
)
const descending = computed(
  () => props.isCurrentlySorted && state.value === 'descending',
)
</script>
