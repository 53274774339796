<template>
  <ModalWrapper
    class="z-[5000101]"
    data-testid="abocard-login-form"
    @click.self="emit('close')"
  >
    <ModalBox class="relative overflow-hidden">
      <LoadingOverlay v-if="loading" />
      <slot>
        <h2 class="typo-teaser-3">Anmelden</h2>
      </slot>
      <form class="typo-body-1" @submit.prevent>
        <input
          v-model="cusno"
          type="text"
          name="cusno"
          class="w-full input-field-gray"
          :class="{ 'input-field-error': cusnoError }"
          placeholder="Abonummer"
          @blur="validateCusno"
        />
        <ErrorMessage v-if="cusnoError">
          {{ cusnoError }}
        </ErrorMessage>

        <input
          v-model="password"
          type="text"
          name="password"
          class="w-full input-field-gray"
          :class="{ 'input-field-error': passwordError }"
          placeholder="Nachname"
          @blur="validatePassword"
        />
        <ErrorMessage v-if="passwordError">
          {{ passwordError }}
        </ErrorMessage>

        <ErrorMessage v-if="genericError">
          <div v-html="genericError" />
        </ErrorMessage>

        <DefaultButton
          class="mt-8"
          :disabled="buttonDisabled"
          @click="submit"
        >
          {{ buttonText }}
        </DefaultButton>
      </form>
    </ModalBox>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useFormValidation } from '../../../composables/useFormValidation'
import DefaultButton from '../DefaultButton.vue'
import ModalBox from '../ModalBox.vue'
import ModalWrapper from '../ModalWrapper.vue'
import ErrorMessage from './ErrorMessage.vue'
import LoadingOverlay from './LoadingOverlay.vue'

const props = withDefaults(
  defineProps<{
    errorMessage?: string
    disabled?: boolean
    buttonText?: string
  }>(),
  {
    errorMessage: '',
    disabled: false,
    buttonText: 'Anmelden',
  },
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'submit', user: any): void
}>()

const cusno = ref('')
const password = ref('')
const cusnoError = ref('')
const passwordError = ref('')
const genericError = ref(props.errorMessage)

const buttonDisabled = computed(
  () => cusno.value === '' || password.value === '' || props.disabled,
)
const loading = computed(() => props.disabled)

watch(
  () => props.errorMessage,
  () => {
    genericError.value = props.errorMessage
  },
)

function validateCusno() {
  cusnoError.value = useFormValidation().validateCusnoInput(cusno.value)
}

function validatePassword() {
  passwordError.value = ''

  if (password.value !== '') {
    return
  }

  passwordError.value = 'Bitte gib deinen Nachnamen ein'
}

function submit() {
  cusnoError.value = ''
  passwordError.value = ''

  validateCusno()
  validatePassword()

  if (cusnoError.value !== '' || passwordError.value !== '') {
    return
  }

  emit('submit', {
    cusno: cusno.value,
    password: password.value,
  })
}
</script>
