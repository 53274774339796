<template>
  <article data-testid="list-teaser" class="flex gap-4">
    <div class="flex flex-col flex-1 gap-3">
      <CategoryLinkLabel
        v-if="post.label?.text"
        :href="post.label?.link"
        :color="labelColor"
      >
        {{ post.label.text }}
      </CategoryLinkLabel>

      <h2 class="typo-teaser-5 line-clamp-3 overflow-ellipsis">
        <a :href="post.link">{{ post.title }}</a>
      </h2>
    </div>

    <div class="relative flex-1">
      <template v-if="filteredImages !== undefined">
        <a :href="post.link">
          <ResponsivePicture :picture="filteredImages" />
        </a>

        <div v-if="filteredImages !== undefined" class="absolute top-2 left-2">
          <MediaTag :is-vplus="post.isVplus" :brand="post.brand || ''" />
        </div>
      </template>
    </div>
  </article>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import CategoryLinkLabel from '../CategoryLinkLabel.vue'
import ResponsivePicture from '../ResponsivePicture.vue'
import MediaTag from './shared/MediaTag.vue'

const props = defineProps<{
  post: Post
  labelColor?: 'YELLOW' | 'WHITE'
}>()

const filteredImages = computed(() => {
  if (!props.post.image.img || !props.post.image.source) {
    return props.post.image
  }

  return {
    ...props.post.image,
    img: {
      ...props.post.image.img,
      srcset: props.post.image.img.srcset?.filter((srcset) => {
        return srcset.src.includes('-4-3-')
      }),
    },
    source: props.post.image.source.map((source) => {
      return {
        ...source,
        srcset: source.srcset?.filter(srcset => srcset.src.includes('-4-3-')),
      }
    }),
  }
})
</script>
