<template>
  <ModalWrapper class="z-[5000101]" @click.self="emit('close')">
    <ModalBox class="overflow-hidden">
      <h2 class="typo-teaser-3">Passwort Zurücksetzen</h2>
      <form class="typo-body-1" @submit.prevent>
        <input
          v-model="username"
          type="text"
          name="username"
          class="w-full input-field-gray"
          :class="{ 'input-field-error': usernameError }"
          placeholder="Benutzername oder E-Mail-Adresse"
        />

        <ErrorMessage v-if="usernameError">
          {{ usernameError }}
        </ErrorMessage>

        <ErrorMessage v-if="genericError">
          {{ genericError }}
        </ErrorMessage>

        <DefaultButton
          class="mt-8"
          :disabled="buttonDisabled"
          @click="emit('submit', username)"
        >
          Passwort zurücksetzen
        </DefaultButton>
      </form>
    </ModalBox>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import DefaultButton from '../DefaultButton.vue'
import ModalBox from '../ModalBox.vue'
import ModalWrapper from '../ModalWrapper.vue'
import ErrorMessage from './ErrorMessage.vue'

const props = withDefaults(
  defineProps<{
    initialUsername?: string
    errorMessage?: string
    disabled?: boolean
  }>(),
  {
    initialUsername: '',
    errorMessage: '',
    disabled: false,
  },
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'submit', username: string): void
}>()

const username = ref(props.initialUsername)
const usernameError = ref()
const genericError = ref(props.errorMessage)

const buttonDisabled = computed(() => username.value === '' || props.disabled)

watch(
  () => props.errorMessage,
  () => {
    genericError.value = props.errorMessage
  },
)
</script>
