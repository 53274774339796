import { useSSO as useLibSSO } from '@russmedia-frontend/np-common'
import AutologinMutation from '~/graphql/mutations/Autologin.gql'
import LoginMutation from '~/graphql/mutations/Login.gql'
import LogoutMutation from '~/graphql/mutations/Logout.gql'
import UpdateUserAttributeMutation from '~/graphql/mutations/UpdateUserAttribute.gql'
import { appendCookieToContainer, splitUpCookie, ssoCookieName, ssoSessionCookieName, waitForCookieAsync } from '~/utils/sso-cookie-helper'

const defaultErrorMessage = 'Es ist ein technischer Fehler aufgetreten. Bitte versuche es in wenigen Minuten noch einmal.'
const localStorageName = 'vodl-user-status'

export function useAuth() {
  const session = useState<ISSOSession | null>('session', () => null)

  const gdprSettings = useGdpr().getSettings()
  watch([session, gdprSettings], setLocalStorage, { deep: true })

  function setLocalStorage() {
    if (import.meta.client) {
      if (session.value === null) {
        deleteLocalStorage()
        return
      }

      const state = {
        plusPremiumSubscriber: session.value?.piano?.abo?.isPlusPremiumSubscriber ?? false,
        plusSubscriber: session.value?.piano?.abo?.isPlusSubscriber ?? false,
        pureSubscriber: session.value?.piano?.abo?.isPurSubscriber ?? false,
        revenueCatPlusPremiumSubscriber: session.value?.piano?.abo?.isRevenueCatPlusPremiumSubscriber ?? false,
        revenueCatPlusSubscriber: session.value?.piano?.abo?.isRevenueCatPlusSubscriber ?? false,
        revenueCatPurSubscriber: session.value?.piano?.abo?.isRevenueCatPurSubscriber ?? false,
        googleIAPPlusSubscriber: session.value?.piano?.abo?.isGoogleIAPPlusSubscriber ?? false,
        guestPlusPremiumSubscriber: session.value?.piano?.abo?.isGuestPlusPremiumSubscriber ?? false,
        guestRevenueCatPlusPremiumSubscriber: session.value?.piano?.abo?.isGuestRevenueCatPlusPremiumSubscriber ?? false,
        NeueSubscriber: session.value?.piano?.abo?.isNeueSubscriber ?? false,
        VNSubscriber: session.value?.piano?.abo?.isVNSubscriber ?? false,
        hasPlusAccess: session.value?.piano?.abo?.hasPlusAccess ?? false,
        hasPlusPremiumAccess: session.value?.piano?.abo?.hasPlusPremiumAccess ?? false,
        commentBan: false,
        consentSettings: gdprSettings.value.granular,
        ssoId: session.value?.id ?? 0,
        email: session.value?.email ?? '',
        hash: session.value?.h ?? '',
        token: session.value?.t ?? '',
      }

      localStorage.setItem(localStorageName, JSON.stringify(state))
    }
  }

  function deleteLocalStorage() {
    if (import.meta.client) {
      localStorage.removeItem(localStorageName)
    }
  }

  return {
    getSSOId: () => session?.value?.id,
    getUser: () => session.value,
    setSSOCookie: (sessionCookie: string, evoSSOCookie: string) => {
      if (!sessionCookie || !evoSSOCookie) {
        session.value = null
        return
      }

      const sessionCookieParts = splitUpCookie(sessionCookie)
      const evoSSOCookieParts = splitUpCookie(evoSSOCookie)

      if (!(['username', 'fullname', 'id', 'email'].every(key => key in sessionCookieParts))) {
        return
      }

      session.value = {
        username: sessionCookieParts.username!,
        fullname: sessionCookieParts.fullname!,
        id: Number.parseInt(sessionCookieParts.id!),
        email: decodeURIComponent(sessionCookieParts.email!),
        h: evoSSOCookieParts.h!,
        t: evoSSOCookieParts.t!,
      }
    },
    getHashTokenFromCookie: () => {
      const evoSSOCookie = useCookie(ssoCookieName)

      if (evoSSOCookie.value) {
        const evoSSOCookieParts = splitUpCookie(evoSSOCookie.value)

        return {
          hash: evoSSOCookieParts.h ?? '',
          token: evoSSOCookieParts.t ?? '',
        }
      }

      return {
        hash: '',
        token: '',
      }
    },
    isLoggedIn: computed(() => {
      return session.value !== null
    }),
    getCookies: () => {
      return {
        session: useCookie(ssoSessionCookieName).value ?? '',
        evo: useCookie(ssoCookieName).value ?? '',
      }
    },
    getLocalUserStatus: () => {
      if (import.meta.client) {
        const userStatus = localStorage.getItem(localStorageName)
        return userStatus ? JSON.parse(userStatus) : null
      }

      return null
    },
    login: async (username: string, password: string) => {
      if (!username || !password) {
        throw new Error('Username and password are required')
      }

      const { mutate: login } = useMutation<{ login: LoginResponse }>(LoginMutation, { variables: { username, password } })

      const result = await login()

      if (result?.errors !== undefined && result.errors.length > 0) {
        throw new Error(result.errors[0]?.message ?? defaultErrorMessage)
      }

      const data = result?.data?.login

      if (!data) {
        throw new Error(defaultErrorMessage)
      }

      const userData = data.user

      session.value = {
        id: Number.parseInt(userData.user_id),
        lastname: userData.lastname ?? '',
        username: userData.login ?? '',
        email: userData.email ?? '',
        h: userData.hash ?? '',
        t: userData.token ?? '',
        piano: data.piano,
        attributes: data.attributes ?? '',
      }

      if (data.cookie) {
        // hack during migration to correct url
        data.cookie = data.cookie.replace('https://nnp.vol.at', '')

        const lastImage = appendCookieToContainer(data.cookie)

        if (!lastImage) {
          // Resolve but SSO cookie could not be set
          throw new Error(defaultErrorMessage)
        }

        await new Promise(resolve => lastImage.addEventListener('load', resolve))

        try {
          await waitForCookieAsync()
        }
        catch {
          throw new Error(defaultErrorMessage)
        }
      }

      const sessionCookie = useCookie(ssoSessionCookieName)
      const evoCookie = useCookie(ssoCookieName)
      if (sessionCookie.value && evoCookie.value) {
        useLibSSO().setSSOCookie(sessionCookie.value, evoCookie.value)
      }

      // update gdpr settings based on the users pure subscription settings.
      if (data.attributes && data.attributes.length > 0) {
        usePureSubscription().setGdprSettings(data.attributes)
      }

      // Login successful, return result
      return true
    },
    autologin: async (hash: string, token: string) => {
      if (!hash || !token) {
        throw new Error('Hash and token are required')
      }

      const { mutate: autologin } = useMutation<{ autologin: AutologinResponse }>(AutologinMutation, { variables: { hash, token } })

      let result
      try {
        result = await autologin()
      }
      catch {
        deleteCookies()
      }

      if (result?.errors !== undefined && result.errors.length > 0) {
        deleteCookies()

        throw new Error(result.errors[0]?.message ?? defaultErrorMessage)
      }

      const data = result?.data?.autologin

      if (!data || data?.code !== 'autologin_successfull') {
        deleteCookies()

        throw new Error(defaultErrorMessage)
      }

      const userData = data.user

      session.value = {
        id: Number.parseInt(userData.user_id),
        lastname: userData.lastname ?? '',
        username: userData.login ?? '',
        email: userData.email ?? '',
        h: userData.hash ?? hash,
        t: userData.token ?? token,
        piano: data.piano,
        attributes: data.attributes ?? null,
      }

      // update gdpr settings based on the users pure subscription settings.
      if (data.attributes && data.attributes.length > 0) {
        usePureSubscription().setGdprSettings(data.attributes)
      }

      // Login successful, return result
      return true
    },
    logout: async () => {
      const { mutate: logout } = useMutation<{ logout: LogoutResponse }>(LogoutMutation)

      session.value = null

      const result = await logout()

      const cookie = result?.data?.logout?.data?.logout_result?.cookie.replace('https://nnp.vol.at', '') ?? false

      // manually delete cookies if logout response does not contain cookie property
      if (!cookie) {
        deleteCookies()
        return true
      }

      const lastImage = appendCookieToContainer(cookie)

      if (!lastImage) {
        deleteCookies()
        return true
      }

      await new Promise(resolve => lastImage.addEventListener('load', resolve))

      deleteCookie(ssoSessionCookieName)
      useLibSSO().setSSOCookie('', '')

      return true
    },
    updateUserAttribute: async (attribute: string, value: string) => {
      const { h, t } = session.value ?? {}

      if (!h || !t) {
        throw new Error('No existing session')
      }

      const { mutate: updateUserAttribute } = useMutation<{ updateUserAttribute: UpdateUserAttributeResponse }>(UpdateUserAttributeMutation, { variables: { hash: h, token: t, attribute, value } })

      const result = await updateUserAttribute()

      if (result?.errors !== undefined && result.errors.length > 0) {
        throw new Error(result.errors[0]?.message ?? defaultErrorMessage)
      }

      if (result?.data?.updateUserAttribute?.error === '1') {
        throw new Error(result.data.updateUserAttribute.errorText ?? defaultErrorMessage)
      }

      return true
    },
  }
}
