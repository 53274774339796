import cookie from 'cookie'

export function useCookie() {
// Function to set a cookie
  function setCookie(
    key: string,
    value: string,
    options: cookie.CookieSerializeOptions = {},
  ): void {
    const serializedCookie = cookie.serialize(key, value, options)
    document.cookie = serializedCookie
  }

  // Function to get a cookie by key
  function getCookie(key: string): string | null {
    const cookies = cookie.parse(document.cookie)
    return cookies[key] || null
  }

  return {
    setCookie,
    getCookie,
  }
}
