export const ssoCookieName = 'EvoSSO'
export const ssoSessionCookieName = 'SsoSessionCookie'
export const ssoCookieContainerName = '#sso-image'

/**
 * Splits a cookie string into an object of key-value pairs.
 * @param {string} cookie - The cookie string to split.
 * @returns {Record<string, string>} An object of key-value pairs.
 */
export function splitUpCookie(cookie: string): Record<string, string> {
  if (!cookie) {
    return {}
  }

  return cookie.split('&').reduce((acc, part) => {
    const arrayOfPart = part.split('=')
    acc[arrayOfPart[0]] = arrayOfPart[1]

    return acc
  }, {} as Record<string, string>)
}

/**
 * Retrieves the cookie container element from the DOM.
 * @returns {HTMLElement | null} The cookie container element, or null if it doesn't exist.
 */
export function getCookieContainer(): HTMLElement | null { return document.querySelector(ssoCookieContainerName) }

/**
 * Appends a cookie image to the cookie container.
 * @param {string} cookieImg - The cookie image to append.
 * @returns {HTMLImageElement | false} The last image in the container, or false if the image could not be found.
 */
export function appendCookieToContainer(cookieImg: string): HTMLImageElement | false {
  let container = getCookieContainer()

  if (!container) {
    const body = document.querySelector('body')

    container = document.createElement('div')
    container.id = ssoCookieContainerName
    body?.append(container)
  }

  const div = document.createElement('div')
  div.innerHTML = cookieImg
  const imgElement = div.firstChild

  if (!imgElement) {
    return false
  }

  container.appendChild(imgElement)
  const images = container?.querySelectorAll('img')

  if (!images || images.length === 0) {
    return false
  }

  return images[images.length - 1]
}

/**
 * Deletes a cookie by setting its expiry date to a past date.
 * @param {string} cookieName - The name of the cookie to delete.
 * @returns {boolean} Always returns true.
 */
export function deleteCookie(cookieName: string): boolean {
  const allCookies = document.cookie.split('; ')

  for (let c = 0; c < allCookies.length; c++) {
    const currentCookieName = encodeURIComponent(allCookies[c].split(';')[0].split('=')[0])
    if (cookieName === currentCookieName) {
      const hn = window.location.hostname.split('.')
      while (hn.length > 0) {
        // without leading domain dot
        let cookieBase = `${currentCookieName}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${hn.join('.')}; path=`
        let p = location.pathname.split('/')
        document.cookie = `${cookieBase}/`
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }

        // with leading domain dot
        cookieBase = `${currentCookieName}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=.${hn.join('.')}; path=`
        p = location.pathname.split('/')
        document.cookie = `${cookieBase}/`
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
        hn.shift()
      }
    }
  }

  return true
}

/**
 * Deletes all SSO cookies.
 */
export function deleteCookies(): void {
  deleteCookie(ssoCookieName)
  deleteCookie(ssoSessionCookieName)
}

/**
 * Retrieves the value of a specific cookie.
 * @param {string} cookieName - The name of the cookie to retrieve.
 * @returns {string | undefined} The value of the cookie, or undefined if the cookie doesn't exist.
 */
export function getCookie(cookieName: string): string | undefined {
  if (!document?.cookie) {
    return
  }

  const cookies = document.cookie.split(';')
  for (const cookie of cookies) {
    const [key, ...value] = cookie.split('=').map(c => c.trim())
    if (key === cookieName) {
      return decodeURI(value.join('='))
    }
  }
}

/**
 * Waits for the sso cookie to be set.
 * @returns {Promise<boolean>} A promise that resolves to true when the cookie is set, or rejects with an error if the cookie isn't set within a certain time.
 */
export function waitForCookieAsync(): Promise<boolean> {
  let cookieCheckCounter = 0

  return new Promise((resolve, reject) => {
    const waitForCookieResolveInterval = setInterval(() => {
      if (cookieCheckCounter < 10) {
        const ssoCookie = getCookie(ssoCookieName)
        if (ssoCookie) {
          clearInterval(waitForCookieResolveInterval)
          resolve(true)
        }
        cookieCheckCounter++
      }
      else {
        clearInterval(waitForCookieResolveInterval)
        reject(new Error('Could not get cookies'))
      }
    }, 300)
  })
}
