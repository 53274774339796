<template>
  <Dropdown
    :items="items"
    :default-text="myTown || 'Gemeinde auswählen'"
    :color="color"
    @option-selected="setTown"
    @options-opened="$emit('optionsOpened')"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useCookie } from '../../composables/useCookie'
import { getSlugFromTitle, townships } from '../../utils/myTownMapping'
import Dropdown from './Dropdown.vue'

defineProps<{
  color?: 'WHITE' | 'BLACK' | 'GRAY'
}>()

const emit = defineEmits(['optionSelected', 'optionsOpened'])

const items = Object.values(townships).map(i => i.title)

const { setCookie, getCookie } = useCookie()

const myTown = ref(getCookie('CurrentCity') || '')

function setTown(item: string) {
  const slug = getSlugFromTitle(item)
  if (!slug) { return }

  myTown.value = slug
  setCookies()
  emit('optionSelected', slug)
}

function setCookies() {
  const time = new Date()
  time.setFullYear(time.getFullYear() + 1)

  let domain = window.location.hostname
  const domainArray = domain.split('.')
  if (domainArray[0] === 'www' || domainArray[0] === 'nnp') {
    domain = domain.replace(domainArray[0], '')
  }
  setCookie('CurrentCity', myTown.value, {
    expires: time,
    domain,
    path: '/',
  })
}
</script>
