export default defineNuxtPlugin({
  name: 'fastGdpr',
  parallel: true,
  async setup() {
    const gdpr = useGdpr()
    const localTcfStatus = gdpr.getLocalTcfStatus()
    const localUserStatus = useAuth().getLocalUserStatus()

    if (localTcfStatus) {
      if (localUserStatus?.pureSubscriber && localUserStatus?.consentSettings) {
        gdpr.updateGranularConsent(localUserStatus.consentSettings)

        return
      }

      if (!localUserStatus?.pureSubscriber) {
        gdpr.acceptAllSettings()

        // load third party scripts
        consentGiven.accept()
      }
    }
  },
})
