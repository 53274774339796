<template>
  <div
    data-testid="dropdown"
    class="relative"
    @click="toggleDropdown"
  >
    <div
      class="flex items-center justify-between min-h-full p-4 border-2 rounded"
      :class="openForm ? colors.wrapper.open : colors.wrapper.closed"
    >
      <span
        role="button"
        class="leading-5 typo-button-text"
        :class="openForm ? colors.label.open : colors.label.closed"
      >
        {{ selectedItem ? selectedItem : defaultText }}
      </span>
      <svg
        v-if="!openForm"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 6L7.64645 9.64645C7.84171 9.84171 8.15829 9.84171 8.35355 9.64645L12 6"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
      <svg
        v-if="openForm"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 10L8.35355 6.35355C8.15829 6.15829 7.84171 6.15829 7.64645 6.35355L4 10"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <ul
      v-if="openForm"
      aria-expanded="false"
      role="list"
      class="absolute z-10 flex flex-col w-full py-4 -mt-1 overflow-y-auto border-2 border-t-0 rounded-b max-h-52 typo-menu-items"
      :class="openForm ? colors.list.open : colors.list.closed"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        class="px-4 py-3 rounded cursor-pointer hover:bg-off-base"
        @click="onOptionSelected(item)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    items: Array<string>
    defaultText: string
    selected?: string
    color?: 'WHITE' | 'BLACK' | 'GRAY'
  }>(),
  {
    color: 'WHITE',
  },
)

const emit = defineEmits<{
  (event: 'optionSelected', value: string): void
  (event: 'optionsOpened'): void
}>()

const white = {
  wrapper: {
    open: 'bg-white border-white rounded-b-none border-b-0',
    closed: 'border-white text-white',
  },
  label: {
    open: 'text-text',
    closed: 'text-white',
  },
  list: {
    open: 'text-text border-white bg-white',
    closed: 'text-white',
  },
}

const black = {
  wrapper: {
    open: 'bg-white border-black rounded-b-none',
    closed: 'border-black text-black',
  },
  label: {
    open: 'text-text',
    closed: 'text-text',
  },
  list: {
    open: 'text-text border-black bg-white',
    closed: 'text-text',
  },
}

const gray = {
  wrapper: {
    open: 'h-9 border-grau-500 rounded-b-none typo-meta-2-bold bg-white',
    closed: 'h-9 border-grau-500 typo-meta-2-bold rounded mix-blend-multiply',
  },
  label: {
    open: 'typo-meta-2-bold',
    closed: 'typo-meta-2-bold',
  },
  list: {
    open: 'typo-meta-2-bold border-grau-500 bg-white',
    closed: 'typo-meta-2-bold',
  },
}

const colors = computed(() => {
  switch (props.color) {
    case 'BLACK':
      return black
    case 'GRAY':
      return gray
    default:
      return white
  }
})

const openForm = ref(false)
const selectedItem = ref(props.selected ?? '')

function toggleDropdown() {
  if (!openForm.value) {
    emit('optionsOpened')
  }

  openForm.value = !openForm.value
}

function onOptionSelected(item: string) {
  selectedItem.value = item
  emit('optionSelected', item)
}
</script>
