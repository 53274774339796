<template>
  <section
    v-if="item.items && item.items.length > 0"
    class="flex flex-col"
    data-testid="card-section"
  >
    <div
      v-if="item.title"
      class="flex items-baseline gap-2 pb-4 uppercase typo-artikel-section-1 dark:text-white"
      @click="emit('titleClicked', item)"
    >
      <h2 class="hover:cursor-pointer">{{ item.title }}</h2>
      <ArrowRightIcon class="w-6 h-6 text-grau-800 hover:cursor-pointer" />
    </div>
    <CardSlider
      :items="item.items"
      :ratio="item.ratio"
      :breakpoints="breakpoints"
      :slides-per-view="slidesPerView"
      :disable-breakpoints="disableBreakpoints"
      :centered-slides="centeredSlides"
      v-bind="$attrs"
    />
    <div class="w-full px-4 mt-4 md:max-w-80 md:mx-auto">
      <DefaultButton
        v-if="item.buttonText"
        color="YellowTransparent"
        @click="emit('buttonClicked', item)"
      >
        {{ item.buttonText }}
      </DefaultButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import ArrowRightIcon from '../../assets/svg/ArrowRight.svg'
import CardSlider from './CardSlider.vue'
import DefaultButton from './DefaultButton.vue'

withDefaults(
  defineProps<{
    item: CardSectionItem
    slidesPerView?: number
    centeredSlides?: boolean
    breakpoints?: any | null
    disableBreakpoints?: boolean
  }>(),
  {
    slidesPerView: 1.1,
    centeredSlides: false,
    breakpoints: null,
    disableBreakpoints: false,
  },
)

const emit = defineEmits<{
  (event: 'titleClicked', item: CardSectionItem): void
  (event: 'buttonClicked', item: CardSectionItem): void
}>()
</script>
