<template>
  <ModalWrapper class="z-[5000101]" @click.self="emit('close')">
    <ModalBox class="relative overflow-hidden">
      <LoadingOverlay v-if="loading" />
      <slot>
        <h2 class="typo-teaser-3">Registrieren</h2>
      </slot>
      <form class="typo-body-1" @submit.prevent>
        <input
          v-model="email"
          type="text"
          name="email"
          class="w-full input-field-gray"
          :class="{
            'input-field-error': emailError,
            'bg-white  text-grau-600/70': initialEmail,
          }"
          placeholder="Email-Adresse"
          :disabled="Boolean(initialEmail)"
          @blur="validateEmail"
        />

        <ErrorMessage v-if="emailError">
          {{ emailError }}
        </ErrorMessage>

        <input
          v-model="password"
          type="password"
          name="password"
          class="w-full input-field-gray"
          :class="{ 'input-field-error': passwordError }"
          placeholder="Passwort (mind. 6 Zeichen)"
          @blur="validatePassword"
        />

        <ErrorMessage v-if="passwordError">
          {{ passwordError }}
        </ErrorMessage>

        <ErrorMessage v-if="genericError">
          <div v-html="genericError" />
        </ErrorMessage>

        <DefaultButton
          class="mt-8"
          :disabled="buttonDisabled"
          @click="submit"
        >
          Registrieren
        </DefaultButton>

        <div v-if="!hideForgetPassword" class="w-full mt-4 text-center">
          <span
            class="cursor-pointer typo-meta-1"
            data-testid="forget-password-button"
            @click="emit('forgetPasswordClick')"
          >
            Passwort vergessen?
          </span>
        </div>
      </form>
    </ModalBox>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useFormValidation } from '../../../composables/useFormValidation'
import DefaultButton from '../DefaultButton.vue'
import ModalBox from '../ModalBox.vue'
import ModalWrapper from '../ModalWrapper.vue'
import ErrorMessage from './ErrorMessage.vue'
import LoadingOverlay from './LoadingOverlay.vue'

const props = withDefaults(
  defineProps<{
    initialEmail?: string
    errorMessage?: string
    disabled?: boolean
    hideForgetPassword?: boolean
  }>(),
  {
    initialEmail: '',
    errorMessage: '',
    disabled: false,
    hideForgetPassword: false,
  },
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'submit', user: any): void
  (event: 'forgetPasswordClick'): void
}>()

const email = ref(props.initialEmail)
const password = ref('')
const emailError = ref('')
const passwordError = ref('')
const genericError = ref(props.errorMessage)

const buttonDisabled = computed(
  () => email.value === '' || password.value === '' || props.disabled,
)
const loading = computed(() => props.disabled)

watch(
  () => props.errorMessage,
  () => {
    genericError.value = props.errorMessage
  },
)

function validateEmail() {
  emailError.value = useFormValidation().validateEmailInput(email.value)
}

function validatePassword() {
  passwordError.value = useFormValidation().validatePasswordInput(
    password.value,
  )
}

function submit() {
  emailError.value = ''
  passwordError.value = ''

  validateEmail()
  validatePassword()

  if (emailError.value !== '' || passwordError.value !== '') {
    return
  }

  emit('submit', {
    email: email.value,
    password: password.value,
  })
}
</script>
