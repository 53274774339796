import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'js-sha256'

export default defineNuxtPlugin({
  name: 'apollo',
  parallel: true,
  setup(nuxtApp) {
    const { clients } = useApollo()
    const config = useRuntimeConfig()

    if (clients === undefined) {
      console.log('No Apollo clients found')
      return
    }

    const selectedClient = clients[config.public.apolloClient]

    if (selectedClient !== undefined) {
      clients.default = selectedClient
    }

    const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true })
    const httpLink = clients.default.link

    // create the new link
    const newLink = persistedQueriesLink.concat(httpLink)

    // set the new link
    clients.default.setLink(newLink)

    // add error handling hook
    nuxtApp.hook('apollo:error', (error) => {
      if (error?.graphQLErrors?.[0]?.message === 'PersistedQueryNotFound') {
        return
      }

      console.error(error)
    // Handle different error cases
    })
  },
})
