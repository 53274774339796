<template>
  <button
    title="Whatsapp"
    type="button"
    class="flex flex-col items-center gap-2"
    data-testid="whatsapp"
    @click="share"
  >
    <WhatsappIcon />
    <span class="typo-meta-1">Auf WhatsApp teilen</span>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import WhatsappIcon from '../../../assets/svg/Whatsapp.svg'

const props = defineProps<{
  url: string
  text: string
}>()

const emit = defineEmits<{
  (event: 'buttonClicked', title: string): void
}>()

const encodedUrl = computed(() => encodeURI(props.url))
const encodedText = computed(() => encodeURI(props.text))

function share() {
  let url
    = `https://web.whatsapp.com/send?text=`
      + `${encodedText.value} - ${encodedUrl.value}`

  // we assume that if it's a mobile device and the user wants to share to whatsapp the app is installed.
  if (/android|iPad|iPhone|iPod/i.test(navigator.userAgent)) {
    url = `whatsapp://send?text=${encodedText.value} - ${encodedUrl.value}`
  }

  window.open(url, '_blank', 'width=650,height=400')

  emit('buttonClicked', 'facebook')
}
</script>
