<template>
  <component
    :is="href ? 'a' : 'button'"
    data-testid="underline-button"
    class="pb-2 border-b-4 typo-button-text w-fit"
    :class="[textColor === 'black' ? 'text-text' : 'text-white', border]"
    :href="href"
    @click="href ? null : $emit('buttonClicked')"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  textColor?: 'black'
  href?: string
  active?: boolean
}>()

defineEmits(['buttonClicked'])

const defaultBorder = {
  href: 'border-b-main-600 hover:border-b-black',
  button: 'border-b-transparent hover:border-b-main-600',
}

const border = computed(() => {
  if (props.active) {
    return defaultBorder.href
  }

  return defaultBorder[props.href ? 'href' : 'button']
})
</script>
