import revive_payload_client_zRK7mK9vBHzWXaKDbu1z2yp7wMVocL9uOEyl11VVwK4 from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iaNBKD85E0BdZlEjRbBcefzt3E0mGDGmWlNHfw1nbdw from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0BNQvVOm0I3u6WBgXzJElk2DmY2hqZnOYxwkNJbl8KM from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5GG1F_cWd4X5l9qyyqjPonk0Qtas0eV9i8fYFmXGUmg from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PTb4UY6ObSnm7nHLKO_fP8ep1zD3K1UhmzBgryi8ff8 from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_g0u3jvjL4aNGWgym1Y9XN3dWZvyBVIB78YEjChifLBc from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6tdyGcUtFO4vFdzNuT95dZj_jqAr4nTYWhF4bFMC68E from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/.nuxt/components.plugin.mjs";
import prefetch_client_MXDoHNNJvckbSV464VOWxC7iP_fi1lD8KGSd6cTxaq8 from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OKb6wA7mBldpMLJltPA35nEfok2GID5FyJLpuziqBW0 from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_R1WVLdNQQHjaHzAq9RLqNIwjj9duyHlwXzIPztulThY from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_qz5Ps0CJHoetaSG5fQPz2EwyU1a4m5YYug6nmElzqvE from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import _01_fastGdpr_client_Be6ToXkY1DkgEESa23icdufB2OvHGRjDvWn71_RDoFM from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/plugins/01.fastGdpr.client.ts";
import apollo_3qH6SW05KVCvapItBFxXX4FGzqO6yPxj6FxqLltOPhE from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/plugins/apollo.ts";
import auth_client_sHUvNvHMQZuIVgj_sFi3xX5pboEobgGGTzHLIOejIv0 from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/plugins/auth.client.ts";
import cmp_client_p0gOj49H3EB1ZyC_r006qjX8Zo2XTjJA4_2waNNRCyE from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/plugins/cmp.client.ts";
import eventHooks_client_WYvXlaRAzyQcw3cb80Uog1gZ65J9Efp5f_NrRqx8_v0 from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/plugins/eventHooks.client.ts";
import tracking_client_wc1GAspYvv9uR6MSVa53hjBeCSJaCk5Aobp4og9PwaM from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/plugins/tracking.client.ts";
export default [
  revive_payload_client_zRK7mK9vBHzWXaKDbu1z2yp7wMVocL9uOEyl11VVwK4,
  unhead_iaNBKD85E0BdZlEjRbBcefzt3E0mGDGmWlNHfw1nbdw,
  router_0BNQvVOm0I3u6WBgXzJElk2DmY2hqZnOYxwkNJbl8KM,
  payload_client_5GG1F_cWd4X5l9qyyqjPonk0Qtas0eV9i8fYFmXGUmg,
  navigation_repaint_client_PTb4UY6ObSnm7nHLKO_fP8ep1zD3K1UhmzBgryi8ff8,
  check_outdated_build_client_g0u3jvjL4aNGWgym1Y9XN3dWZvyBVIB78YEjChifLBc,
  chunk_reload_client_6tdyGcUtFO4vFdzNuT95dZj_jqAr4nTYWhF4bFMC68E,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_MXDoHNNJvckbSV464VOWxC7iP_fi1lD8KGSd6cTxaq8,
  plugin_OKb6wA7mBldpMLJltPA35nEfok2GID5FyJLpuziqBW0,
  plugin_R1WVLdNQQHjaHzAq9RLqNIwjj9duyHlwXzIPztulThY,
  plugin_qz5Ps0CJHoetaSG5fQPz2EwyU1a4m5YYug6nmElzqvE,
  _01_fastGdpr_client_Be6ToXkY1DkgEESa23icdufB2OvHGRjDvWn71_RDoFM,
  apollo_3qH6SW05KVCvapItBFxXX4FGzqO6yPxj6FxqLltOPhE,
  auth_client_sHUvNvHMQZuIVgj_sFi3xX5pboEobgGGTzHLIOejIv0,
  cmp_client_p0gOj49H3EB1ZyC_r006qjX8Zo2XTjJA4_2waNNRCyE,
  eventHooks_client_WYvXlaRAzyQcw3cb80Uog1gZ65J9Efp5f_NrRqx8_v0,
  tracking_client_wc1GAspYvv9uR6MSVa53hjBeCSJaCk5Aobp4og9PwaM
]