<template>
  <ul
    class="flex gap-10 md:flex-row"
    :class="{ 'flex-col': useCustomSharing }"
    data-testid="social-bookmarks"
  >
    <li
      v-for="(item, index) in items"
      :key="index"
      class="flex flex-col items-center cursor-pointer"
    >
      <component
        :is="item.component"
        :url="url"
        :text="text"
        v-bind="$attrs"
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import CopyClipboard from './CopyClipboard.vue'
import ShareToEmail from './ShareToEmail.vue'
import ShareToFacebook from './ShareToFacebook.vue'
import ShareToWhatsapp from './ShareToWhatsapp.vue'
import ShareToX from './ShareToX.vue'
import UniversalShare from './UniversalShare.vue'

const props = withDefaults(
  defineProps<{
    url: string
    text: string
    bookmarks?: SocialBookmarkType[]
    useNativeSharing?: boolean
  }>(),
  {
    url: '',
    text: '',
    bookmarks: () => [
      'clipboard',
      'universal',
      'email',
      'facebook',
      'x',
      'whatsapp',
    ],
    useNativeSharing: true,
  },
)

const bookmarkComponents: Record<string, any> = {
  clipboard: CopyClipboard,
  email: ShareToEmail,
  facebook: ShareToFacebook,
  x: ShareToX,
  whatsapp: ShareToWhatsapp,
  universal: UniversalShare,
}

const useCustomSharing = computed(
  () => !props.useNativeSharing || !navigator.share,
)

const items = computed((): Record<string, any> => {
  return props.bookmarks
    .map((bookmark: string) => {
      // Firefox does not support the native sharing API.
      // Therefore we handle the sharing differently.
      if (useCustomSharing.value && bookmark !== 'universal') {
        return { component: bookmarkComponents[bookmark] || null }
      }
      else if (
        !useCustomSharing.value
        && (bookmark === 'clipboard' || bookmark === 'universal')
      ) {
        return { component: bookmarkComponents[bookmark] || null }
      }

      return null
    })
    .filter((item: { component: any } | null) => item !== null)
})
</script>
