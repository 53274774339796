export function useOewa() {
  const cp = useDevice().isMobileOrTablet ? 'RedCont/Homepage/Homepage/moewa/VOL' : 'RedCont/Homepage/Homepage/VOL'
  const { oewa_domain } = useRuntimeConfig().public
  return {
    trackInitial: () => {
      window.IOMm('configure', { st: 'at_w_atvol', dn: oewa_domain, cn: 'at', mh: 5 })
      window.IOMm('pageview', { cp })
    },
    trackPageview: (type: string) => {
      if (typeof window.IOMm === 'undefined') {
        return
      }

      const modOEWA = cp
      const agency = modOEWA.substring(modOEWA.lastIndexOf('/') + 1)
      const elements = modOEWA.split('/')

      if (modOEWA.includes('Homepage')) {
        elements[1] = 'Nachrichten'
        elements[2] = 'LokaleNachrichten'
      }

      let newCP = ''

      if (useDevice().isMobileOrTablet) {
        // new OEWA tracking code
        newCP
          = `${elements[0]
          }/${
            elements[1]
          }/${
            elements[2]
          }/moewa${
            type
          }/${
            agency}`
      }
      else {
        // new OEWA tracking code
        newCP
          = `${elements[0]
          }/${
            elements[1]
          }/${
            elements[2]
          }${type
          }/${
            agency}`
      }

      if (typeof window.IOMm !== 'undefined') {
        window.IOMm('pageview', { cp: newCP })
      }
    },
  }
}
