export const townships: Record<string, { title: string }> = {
  alberschwende: { title: 'Alberschwende' },
  altach: { title: 'Altach' },
  andelsbuch: { title: 'Andelsbuch' },
  au: { title: 'Au' },
  bartholomaeberg: { title: 'Bartholom\u00E4berg' },
  bezau: { title: 'Bezau' },
  bildstein: { title: 'Bildstein' },
  bizau: { title: 'Bizau' },
  blons: { title: 'Blons' },
  bludenz: { title: 'Bludenz' },
  bludesch: { title: 'Bludesch' },
  brand: { title: 'Brand' },
  bregenz: { title: 'Bregenz' },
  buch: { title: 'Buch' },
  buers: { title: 'B\u00FCrs' },
  buerserberg: { title: 'B\u00FCrserberg' },
  dalaas: { title: 'Dalaas' },
  damuels: { title: 'Dam\u00FCls' },
  doren: { title: 'Doren' },
  dornbirn: { title: 'Dornbirn' },
  duens: { title: 'D\u00FCns' },
  duenserberg: { title: 'D\u00FCnserberg' },
  egg: { title: 'Egg' },
  eichenberg: { title: 'Eichenberg' },
  feldkirch: { title: 'Feldkirch' },
  fontanella: { title: 'Fontanella' },
  frastanz: { title: 'Frastanz' },
  fraxern: { title: 'Fraxern' },
  fussach: { title: 'Fu\u00DFach' },
  gaissau: { title: 'Gai\u00DFau' },
  gaschurn: { title: 'Gaschurn' },
  goefis: { title: 'G\u00F6fis' },
  goetzis: { title: 'G\u00F6tzis' },
  hard: { title: 'Hard' },
  hittisau: { title: 'Hittisau' },
  hoechst: { title: 'H\u00F6chst' },
  hoerbranz: { title: 'H\u00F6rbranz' },
  hohenems: { title: 'Hohenems' },
  hohenweiler: { title: 'Hohenweiler' },
  innerbraz: { title: 'Innerbraz' },
  kennelbach: { title: 'Kennelbach' },
  klaus: { title: 'Klaus' },
  kloesterle: { title: 'Kl\u00F6sterle' },
  koblach: { title: 'Koblach' },
  krumbach: { title: 'Krumbach' },
  langenbeibregenz: { title: 'Langen bei Bregenz' },
  langenegg: { title: 'Langenegg' },
  laterns: { title: 'Laterns' },
  lauterach: { title: 'Lauterach' },
  lech: { title: 'Lech' },
  lingenau: { title: 'Lingenau' },
  lochau: { title: 'Lochau' },
  loruens: { title: 'Lor\u00FCns' },
  ludesch: { title: 'Ludesch' },
  lustenau: { title: 'Lustenau' },
  maeder: { title: 'M\u00E4der' },
  meiningen: { title: 'Meiningen' },
  mellau: { title: 'Mellau' },
  mittelberg: { title: 'Mittelberg' },
  moeggers: { title: 'M\u00F6ggers' },
  nenzing: { title: 'Nenzing' },
  nueziders: { title: 'N\u00FCziders' },
  raggal: { title: 'Raggal' },
  rankweil: { title: 'Rankweil' },
  reuthe: { title: 'Reuthe' },
  riefensberg: { title: 'Riefensberg' },
  roens: { title: 'R\u00F6ns' },
  roethis: { title: 'R\u00F6this' },
  stanton: { title: 'Sankt Anton im Montafon' },
  stgallenkirch: { title: 'Sankt Gallenkirch' },
  stgerold: { title: 'Sankt Gerold' },
  satteins: { title: 'Satteins' },
  schlins: { title: 'Schlins' },
  schnepfau: { title: 'Schnepfau' },
  schnifis: { title: 'Schnifis' },
  schoppernau: { title: 'Schoppernau' },
  schroecken: { title: 'Schr\u00F6cken' },
  schruns: { title: 'Schruns' },
  schwarzach: { title: 'Schwarzach' },
  schwarzenberg: { title: 'Schwarzenberg' },
  sibratsgfaell: { title: 'Sibratsgf\u00E4ll' },
  silbertal: { title: 'Silbertal' },
  sonntag: { title: 'Sonntag' },
  stallehr: { title: 'Stallehr' },
  sulz: { title: 'Sulz' },
  sulzberg: { title: 'Sulzberg' },
  thueringen: { title: 'Th\u00FCringen' },
  thueringerberg: { title: 'Th\u00FCringerberg' },
  tschagguns: { title: 'Tschagguns' },
  uebersaxen: { title: '\u00DCbersaxen' },
  vandans: { title: 'Vandans' },
  viktorsberg: { title: 'Viktorsberg' },
  warth: { title: 'Warth' },
  weiler: { title: 'Weiler' },
  wolfurt: { title: 'Wolfurt' },
  zwischenwasser: { title: 'Zwischenwasser' },
}

export function getTownship(township: string) {
  return townships[township] || null
}

export function getSlugFromTitle(title: string) {
  const entries = Object.entries(townships)
  const slugIndex = entries.findIndex(([_, row]) => row.title === title)

  if (slugIndex === -1) {
    return null
  }

  return entries[slugIndex]?.[0] || null
}

export function getSlugForGRW(slug: string) {
  const mapping: Record<string, string> = {
    bartholomaeberg: 'bartholomaberg',
    buers: 'burs',
    buerserberg: 'burserberg',
    damuels: 'damuls',
    duens: 'duns',
    duenserberg: 'dunserberg',
    goefis: 'gofis',
    goetzis: 'gotzis',
    hoechst: 'hochst',
    hoerbranz: 'horbranz',
    kloesterle: 'klosterle',
    loruens: 'loruns',
    maeder: 'mader',
    moeggers: 'moggers',
    nueziders: 'nuziders',
    roens: 'rons',
    roethis: 'rothis',
    schroecken: 'schrocken',
    thueringen: 'thuringen',
    thueringerberg: 'thuringenberg',
    uebersaxen: 'ubersaxen',
    stanton: 'st-anton',
    stgallenkirch: 'st-gallenkirch',
    stgerold: 'st-gerold',
  }

  return mapping[slug] || slug
}
