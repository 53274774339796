<template>
  <div
    data-testid="search-box"
    class="flex items-center h-12 p-3 rounded mix-blend-multiply bg-grau-300"
    :class="[color]"
  >
    <Search data-testid="search-button" @click="emitSearch" />
    <input
      v-model.lazy="value"
      class="flex-1 mx-2 focus:outline-none typo-menu-items placeholder-grau-800 bg-grau-300"
      :class="[color]"
      autocomplete="off"
      name="search"
      type="text"
      :placeholder="placeholderText"
      @keyup.enter="emitSearch"
    />
    <Cross
      v-show="modelValue !== ''"
      class="hidden sm:inline"
      @click="clearInput"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Cross from '../../../assets/svg/Cross.svg'
import Search from '../../../assets/svg/Search.svg'

const props = withDefaults(
  defineProps<{
    modelValue: string
    placeholderText?: string
    color?: string
  }>(),
  {
    placeholderText: 'Suchbegriff eingeben',
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'searchEntered', value: string): void
}>()

const value = computed({
  get: () => props.modelValue,
  set: newValue => emit('update:modelValue', newValue),
})

function clearInput() {
  value.value = ''
}

function emitSearch() {
  emit('searchEntered', value.value)
}
</script>
