<template>
  <div
    data-testid="user-image"
    class="flex items-center justify-center w-10 h-10 rounded-full"
    :style="`background:${colorBackground}`"
  >
    <span class="mb-[4px] typo-artikel-sub" :style="`color:${colorForeground}`">
      {{ firstLetter }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{ userName: string }>()
const firstLetter = computed(() => props.userName?.[0]?.toUpperCase() ?? '')

const colourMap: Record<number, { background: string, foreground: string }> = {
  0: {
    background: '#FFBDE5',
    foreground: '#FF2424',
  },
  1: {
    background: '#FF2424',
    foreground: '#FFBDE5',
  },
  2: {
    background: '#7C15FF',
    foreground: '#FFD200',
  },
  3: {
    background: '#FFD200',
    foreground: '#7C15FF',
  },
  4: {
    background: '#46FFC8',
    foreground: '#3624FF',
  },
  5: {
    background: '#3624FF',
    foreground: '#46FFC8',
  },
}

const group = computed(() => {
  const group = firstLetter.value
  const groupNumber = (group.charCodeAt(0) - 64) % 6

  if (groupNumber < 0 || groupNumber > 5) {
    return colourMap[0]
  }

  return colourMap[groupNumber]
})

const colorBackground = computed(() => group.value?.background)
const colorForeground = computed(() => group.value?.foreground)
</script>
