interface GtmInstance {
  dataLayer: any[]
}

const gtmInstance: Ref<GtmInstance | null> = ref(null)

export function useGtm() {
  return {
    getInstance: () => gtmInstance.value,
    setInstance: (instance: GtmInstance) => {
      gtmInstance.value = instance
    },
    trackEvent: (data: Record<string, any>) => {
      if (!gtmInstance.value) {
        return
      }

      gtmInstance.value.dataLayer.push(data)

      document.dispatchEvent(
        new CustomEvent('hansrmdtevent', { detail: data }),
      )
    },
  }
}
