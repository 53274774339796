
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexV86H6J0otCjQDeCARsCtR21y87_45L57xkD8ldCmcXY8cMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/index.vue?macro=true";
import { default as suche1YCyuub1cCTFpcTcXN_J7w_4593Yk8ma0XOVBjyXX9NaQMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/suche.vue?macro=true";
import { default as _91page_93WvShguusKUBGJn0U_45lHDWdYlJupMIAx781j_wQlPtxkMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/[page].vue?macro=true";
import { default as profilol0JgJ76Oi1MEpCD4O8OBGmovWB171_sEJxnqss7yjUMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/profil.vue?macro=true";
import { default as navL7nyrezmev37CbbCfpvBlNlR_450p43v6bjJkrOYsWnnIMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/onboarding/nav.vue?macro=true";
import { default as _91id_93qCiRMaXAf1YaYiF1fFZ_45m3jBrjIJWtA2q6UhNp0uRBMMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/[post_slug]/[id].vue?macro=true";
import { default as verkehrR0nqth_JfPwFeQH9HqtCZLVVu7Aj6XrdfdWReGA5Ge0Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/verkehr.vue?macro=true";
import { default as horoskopvUDndpBgZgzg52NMcY9cAzVqBzmyvek0zag_45OFn_8lcMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/horoskop.vue?macro=true";
import { default as newsletterQOaWXPzrqTez3qvvVJOgfr5xWvbzln2SlyPHwObUy2oMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/newsletter.vue?macro=true";
import { default as notdiensteM8MtepPY7Y6iQ7giOZB5Tt8YjRDMsMRfyVwz9s19yvkMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/notdienste.vue?macro=true";
import { default as _91_91page_93_93HRCdoqmXJyN7cSZbR3hZ1F_45C3KzBSKZ0aq3JCgSm1LkMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/themen/[[_]]/[[page]].vue?macro=true";
import { default as spritpreise_45KRr59snfy_ZpwuYiRFYRE8exixMo2gz3nTvHcu1q84Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/spritpreise.vue?macro=true";
import { default as mondkalenderC2pweUiSc6gCzum84Nn4f2wUBA_45f0yWi0jIvKy9jiIwMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/mondkalender.vue?macro=true";
import { default as _91_91page_93_937cGXAdI_baZQa65xESePoBlRD_45071u5KXdeYKzSriW8Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/themen/[slug]/[[_]]/[[page]].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/index.vue")
  },
  {
    name: "suche",
    path: "/suche",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/suche.vue")
  },
  {
    name: "page",
    path: "/:page()",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/[page].vue")
  },
  {
    name: "profil",
    path: "/profil",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/profil.vue")
  },
  {
    name: "onboarding-nav",
    path: "/onboarding/nav",
    meta: navL7nyrezmev37CbbCfpvBlNlR_450p43v6bjJkrOYsWnnIMeta || {},
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/onboarding/nav.vue")
  },
  {
    name: "post_slug-id",
    path: "/:post_slug()/:id()",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/[post_slug]/[id].vue")
  },
  {
    name: "verkehr",
    path: "/verkehr",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/verkehr.vue")
  },
  {
    name: "horoskop",
    path: "/horoskop",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/horoskop.vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/newsletter.vue")
  },
  {
    name: "notdienste",
    path: "/notdienste",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/notdienste.vue")
  },
  {
    name: "themen-_-page",
    path: "/themen/:_?/:page?",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/themen/[[_]]/[[page]].vue")
  },
  {
    name: "spritpreise",
    path: "/spritpreise",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/spritpreise.vue")
  },
  {
    name: "mondkalender",
    path: "/mondkalender",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/(services)/mondkalender.vue")
  },
  {
    name: "themen-slug-_-page",
    path: "/themen/:slug()/:_?/:page?",
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/pages/themen/[slug]/[[_]]/[[page]].vue")
  }
]