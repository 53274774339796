<template>
  <div class="py-4 rounded bg-main-200">
    <div class="mx-6 mt-2">
      <MyTownDropdown
        color="BLACK"
        @option-selected="setTown"
        @options-opened="trackClick('gemeinde-dropdown', '', '', 'dropdown-opened')"
      />
    </div>

    <div v-if="!isFetching && posts.length > 0" class="flex flex-col gap-6 mx-2 mt-10">
      <ListTeaser
        v-for="(post, index) in posts"
        :key="post.id"
        :post="post"
        @click="trackClick('artikelliste', post.link, post.title, 'linkclick', (index + 1).toString())"
      />
    </div>
    <LoadingSpinner v-else class="mt-10 min-h-80" />

    <div class="mt-10">
      <UnderlineButton
        :href="linkToMyTown"
        class="block mx-auto"
        text-color="black"
        :active="true"
        @click="trackClick('link-gemeinde-seite', linkToMyTown, 'Zur Gemeindeseite')"
      >
        Zur Gemeindeseite
      </UnderlineButton>
    </div>

    <div class="flex items-start justify-center gap-1 mt-4">
      <GRW2025 class="-mt-1.5" />
      <UnderlineButton
        :href="linkToTopic"
        text-color="black"
        :active="true"
        @click="trackClick('link-gemeindewahl-seite', linkToTopic, topic.buttonText)"
      >
        {{ topic.buttonText }}
      </UnderlineButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFetch } from '@vueuse/core'
import { computed, ref } from 'vue'
import GRW2025 from '../../../assets/svg/GRW2025.svg'
import { useCookie } from '../../../composables/useCookie'
import { useTracking } from '../../../composables/useTracking'
import { getSlugForGRW, townships } from '../../../utils/myTownMapping'
import LoadingSpinner from '../LoadingSpinner.vue'
import MyTownDropdown from '../MyTownDropdown.vue'
import ListTeaser from '../Teasers/ListTeaser.vue'
import UnderlineButton from '../UnderlineButton.vue'

const { topic } = defineProps<{
  topic: { link: string, buttonText: string }
}>()

const { getCookie } = useCookie()

const myTown = ref(getCookie('CurrentCity') || '')

const topicMyTown = computed(() => {
  return myTown.value ? getSlugForGRW(myTown.value) : ''
})

const url = computed(() => {
  const baseURL = '/wp-json/russmedia-topics/v1/topics'
  if (myTown.value === '') { return `${baseURL}/gemeindewahlen?per_page=3` }

  return `${baseURL}/gemeindewahl-${topicMyTown.value}?per_page=3`
})

const { data, isFetching } = useFetch(url, { refetch: true }).get().json()

const posts = computed<Post[]>(() => {
  if (!data.value) { return [] }

  return data.value.hits.map(postFromRawData)
})

function setTown(slug: string) {
  const title = townships[slug].title
  trackClick('gemeinde-dropdown', '', title, 'gemeindename')

  myTown.value = slug
}

const linkToTopic = computed(() => {
  const baseURL = '/themen/gemeindewahl'
  if (myTown.value === '') {
    return `${baseURL}en`
  }

  return `${baseURL}-${topicMyTown.value}`
})

const linkToMyTown = computed(() => {
  return `/gemeinde/${myTown.value}`
})

function trackClick(event: string, url: string, elementText: string, action: string = 'linkclick', elementPosition: string = '') {
  useTracking().clicktracking(event, 'hp-sb-widget-gemeindwahl', action, url, elementText, elementPosition)
}

function postFromRawData(rawPostData: any): Post {
  return {
    id: rawPostData.id,
    date: rawPostData.date ?? '',
    formatted_date: rawPostData.formatted_date ?? '',
    title: rawPostData.title?.rendered ?? '',
    slug: rawPostData.slug ?? '',
    link: rawPostData.link ?? null,
    image: rawPostData.featured_image ?? {},
    label: {
      text: 'Gemeindewahlen',
      link: '/themen/gemeindewahlen',
    },
    commentCount: rawPostData.comment_count ?? 0,
    pictureCount: rawPostData.picture_count ?? 0,
    isVplus: rawPostData.is_premium ?? false,
    brand: rawPostData.brand ?? '',
    contentBlocks: rawPostData.content_blocks ?? [],
  }
}
</script>
