export function usePureSubscription() {
  return {
    saveConsents,
    getConsentsAttributeName,
    setGdprSettings,
    isPurSubscriber,
  }
}

/**
 * Get the name of the sso user attribute for pure subscription consents
 */
function getConsentsAttributeName() {
  return `${useRuntimeConfig().public.portal}_pure_subscription_consents`
}

/**
 * Check if the current user is a pur subscriber
 *
 * @returns True if the user is a pur subscriber, false otherwise
 */
function isPurSubscriber(): boolean {
  const user = useAuth().getUser()

  return user?.piano?.abo?.isPurSubscriber ?? false
}

/**
 * Set the gdpr settings based on the sso user attribute
 *
 * @param attributes - The user attributes
 */
function setGdprSettings(attributes: UserAttribute[]) {
  const pureSubscriptionConsents = attributes.find(attr => attr.name === getConsentsAttributeName())

  if (pureSubscriptionConsents) {
    try {
      const granularConsent = JSON.parse(pureSubscriptionConsents?.value ?? '{}')
      useGdpr().updateGranularConsent(granularConsent)
    }
    catch (error) {
      console.error('Error updating granular consent', error)
    }
  }
}

/**
 * Save the gdpr consents to the sso user attribute
 * if the user is a pur subscriber
 */
async function saveConsents() {
  if (!isPurSubscriber()) {
    return
  }

  const embedProviders = useGdpr().getSettings().value.granular
  if (!embedProviders) {
    return
  }

  await useAuth().updateUserAttribute(getConsentsAttributeName(), JSON.stringify(embedProviders))
}
