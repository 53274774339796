import type { Component, ShallowRef } from 'vue'
import { LazyModalModalsForgetPassword, LazyModalModalsLogin, LazyModalModalsPurePreferences, LazyModalModalsRegister } from '#components'
import { computed } from 'vue'

// if a new Modal add here
type ModalOptions = 'Login' | 'Register' | 'ForgetPassword' | 'PurePreferences'

let dialog: ShallowRef<HTMLDialogElement | null> | null = null

export function useModal() {
  const modal = useState<Component | null>('modal', () => shallowRef(null))

  const openDialog = () => {
    dialog?.value?.showModal()
  }

  const setDialog = (dialogElement: ShallowRef<HTMLDialogElement | null>) => {
    dialog = dialogElement

    if (modal.value !== null) {
      openDialog()
    }
  }

  const openModal = (selectedModal: ModalOptions) => {
    // if a new Modal add here
    modal.value = {
      Login: LazyModalModalsLogin,
      Register: LazyModalModalsRegister,
      ForgetPassword: LazyModalModalsForgetPassword,
      PurePreferences: LazyModalModalsPurePreferences,
    }[selectedModal]

    openDialog()
  }

  const closeModal = () => {
    modal.value = null
    dialog?.value?.close()
  }

  const isOpen = computed(() => modal.value !== null && dialog !== null)

  return {
    openModal,
    closeModal,
    setDialog,
    isOpen,
    modal,
  }
}
