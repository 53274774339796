<template>
  <section
    v-if="items && items.length > 0"
    data-testid="card-slider"
    class="relative w-full h-full"
  >
    <swiper-container
      :modules="[Pagination, Navigation]"
      :pagination="{ el: '.card-swiper-pagination', clickable: true }"
      :navigation="{
        prevEl: '.card-swiper-button-prev',
        nextEl: '.card-swiper-button-next',
      }"
      :breakpoints="swiperBreakpoints"
      :centered-slides="centeredSlides"
      :clickable="true"
      :slides-per-view="slidesPerView"
      :space-between="10"
      :loop="items.length > 4"
    >
      <swiper-slide
        v-for="(item, index) in items"
        :key="index"
        class="md:max-w-[350px]"
        :class="{ 'md:max-w-[200px]': 'portrait' === ratio }"
      >
        <Card
          :item="item"
          :ratio="ratio"
          v-bind="$attrs"
        />
      </swiper-slide>
    </swiper-container>
    <ArrowRight class="hidden text-white card-swiper-button-prev" />
    <ArrowRight class="hidden text-white card-swiper-button-next" />
    <div class="card-swiper-pagination" />
  </section>
</template>

<script setup lang="ts">
import { register } from 'swiper/element/bundle'
import { Navigation, Pagination } from 'swiper/modules'
import { ref } from 'vue'
import ArrowRight from '../../assets/svg/ArrowRight.svg'
import Card from './Card.vue'
import 'swiper/swiper-bundle.css'

const props = withDefaults(
  defineProps<{
    items: CardItem[]
    slidesPerView?: number
    centeredSlides?: boolean
    ratio?: 'small' | 'portrait'
    breakpoints?: any
    disableBreakpoints?: boolean
  }>(),
  {
    slidesPerView: 1.1,
    centeredSlides: false,
    ratio: 'small',
    disableBreakpoints: false,
  },
)

register()

const swiperBreakpoints = ref({})

if (!props.disableBreakpoints) {
  if (props.breakpoints) {
    swiperBreakpoints.value = props.breakpoints
  }
  else {
    swiperBreakpoints.value = {
      592: {
        slidesPerView: props.ratio === 'portrait' ? 3 : 2,
      },
      800: {
        slidesPerView: 'auto',
        loopedSlides: props.items?.length ?? false,
        allowTouchMove: false,
      },
    }
  }
}
</script>

<style>
.card-swiper-pagination {
  @apply text-center;

  .swiper-pagination-bullet {
    @apply bg-grau-600 opacity-30 dark:opacity-100;
  }

  .swiper-pagination-bullet-active {
    @apply opacity-100 bg-main-600;
  }
}

@screen md {
  .card-swiper-button-next,
  .card-swiper-button-prev {
    @apply absolute right-0 z-20 items-center w-9 top-[calc(50%-8px)] -translate-y-1/2 hidden text-center md:flex;
  }

  .card-swiper-button-prev {
    @apply left-6 rotate-180;
  }

  .card-swiper-button-next {
    @apply right-6;
  }
}
</style>
