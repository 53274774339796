import routerOptions0 from "/builds/DEVV/russmedia-frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/DEVV/russmedia-frontend-nuxt3/apps/np/vol/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}