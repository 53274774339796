export function usePaginationUrl() {
  return {
    getPaginationUrl: (page: number, paginationUrlConfig = {} as PaginationUrlConfig) => {
      if (page === 1 && paginationUrlConfig.firstPageUrl) {
        return paginationUrlConfig.firstPageUrl
      }

      if (page >= 1 && paginationUrlConfig.pageUrlPattern) {
        return paginationUrlConfig.pageUrlPattern.replace('{page}', page.toString())
      }

      return ''
    },

    hasPaginationUrl: (page: number, paginationUrlConfig = {} as PaginationUrlConfig) => {
      if (page >= 1 && (paginationUrlConfig.firstPageUrl || paginationUrlConfig.pageUrlPattern)) {
        return true
      }

      return false
    },
  }
}
