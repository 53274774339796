<template>
  <div class="flex justify-center gap-3">
    <div
      v-for="(page, index) in pages"
      :key="index"
      class="flex gap-3"
    >
      <span
        v-if="page === '...'"
        class="cursor-pointer typo-menu-items text-grau-500"
        @click="expandPages(index)"
      >
        {{ page }}
      </span>

      <a
        v-else-if="
          usePaginationUrl().hasPaginationUrl(page, paginationUrlConfig)
        "
        :href="usePaginationUrl().getPaginationUrl(page, paginationUrlConfig)"
        class="cursor-pointer typo-menu-items"
        :class="{ 'text-grau-800': page !== currentPage }"
      >
        {{ page }}
      </a>

      <span
        v-else
        class="cursor-pointer typo-menu-items"
        :class="{ 'text-grau-800': page !== currentPage }"
        @click="handlePageClick(page)"
      >
        {{ page }}
      </span>

      <span
        v-if="index + 1 !== pages.length"
        class="typo-menu-items text-grau-500"
      >
        /
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { usePaginationUrl } from '../../composables/usePaginationUrl'

const props = withDefaults(
  defineProps<{
    totalPages: number
    currentPage: number
    paginationUrlConfig?: PaginationUrlConfig
  }>(),
  {
    totalPages: 1,
    currentPage: 1,
    paginationUrlConfig: () => ({}) as PaginationUrlConfig,
  },
)

const emit = defineEmits<{
  (event: 'pageClicked', page: number): void
}>()

const expandedRange = ref<number | null>(null)

const pages = computed(() => {
  const pages: any[] = [1]
  const maxVisiblePages = 5
  const totalPages = props.totalPages
  const currentPage = props.currentPage

  if (totalPages <= maxVisiblePages) {
    // If totalPages is not greater than maxVisiblePages, just fill the pages array with the page numbers
    for (let i = 2; i <= totalPages; i++) {
      pages.push(i)
    }

    return pages
  }

  // Determine if a range has been expanded
  const rangeToExpand = expandedRange.value || currentPage

  let startPage = Math.max(
    2,
    rangeToExpand - Math.floor((maxVisiblePages - 3) / 2),
  )
  startPage = Math.min(startPage, totalPages - maxVisiblePages + 2)

  // Add the ellipsis if the start page is not 2
  if (startPage !== 2) {
    pages.push('...')
  }

  // Add the visible pages
  for (let i = 0; i < maxVisiblePages - 2; i++) {
    const page = startPage + i
    if (page < totalPages) {
      pages.push(page)
    }
  }

  // Add the ellipsis if there are pages between the last visible page and the last page
  if (startPage + maxVisiblePages - 3 < totalPages - 1) {
    pages.push('...')
  }

  // Always add the last page
  pages.push(totalPages)

  return pages
})

function handlePageClick(page: number) {
  emit('pageClicked', page)
}

function expandPages(index: number) {
  // Expand range by setting the expanded range value based on the index of the clicked ellipsis
  if (index === 1) {
    expandedRange.value = pages.value[2] as number
  }
  else if (index === pages.value.length - 2) {
    expandedRange.value = pages.value[pages.value.length - 3] as number
  }
}
</script>
