<template>
  <button
    title="Facebook"
    type="button"
    class="flex flex-col items-center gap-2"
    data-testid="facebook"
    @click="share"
  >
    <FacebookIcon />
    <span class="typo-meta-1">Auf Facebook teilen</span>
  </button>
</template>

<script setup lang="ts">
import FacebookIcon from '../../../assets/svg/Facebook.svg'

const props = defineProps<{
  url: string
  text: string
}>()

const emit = defineEmits<{
  (event: 'buttonClicked', title: string): void
}>()

declare global {
  interface Window {
    FB: any
  }
}

function share() {
  if (typeof window.FB?.ui !== 'function') {
    emit('buttonClicked', 'facebook')
    return
  }

  window.FB.ui({
    method: 'share',
    mobile_iframe: true,
    href: props.url,
  })

  emit('buttonClicked', 'facebook')
}
</script>
