<template>
  <tr class="snow-report-entry bg-grau-500">
    <td class="pl-2 rounded-l" colspan="2">
      <div class="flex gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 10"
          class="w-2.5 min-w-2.5"
          :class="{
            'text-green-500': hasOpenLifts,
            'text-red-500': !hasOpenLifts,
          }"
        >
          <circle
            cx="5"
            cy="5"
            r="5"
            fill="currentColor"
          />
        </svg>
        {{ item.resort }}
      </div>
    </td>
    <td>{{ base }}</td>
    <td>{{ top }}</td>
    <td class="rounded-r">{{ item.openLifts }}/{{ item.lifts }}</td>
  </tr>
</template>

<script setup lang="ts">
const props = defineProps<{
  item: SnowReport
}>()

const base = props.item.depths.base > 0 ? `${props.item.depths.base}cm` : '-'
const top = props.item.depths.top > 0 ? `${props.item.depths.top}cm` : '-'
const hasOpenLifts = props.item.openLifts > 0
</script>

<style>
.snow-report-entry {
  td {
    @apply py-2 text-center;
  }

  td:first-child {
    @apply text-left;
  }
}
</style>
