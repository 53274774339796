<template>
  <article data-testid="large-teaser">
    <div class="relative">
      <a :href="post.link">
        <ResponsivePicture
          :picture="post.image"
          :additional-class="
            hasAssociatedPosts
              ? 'associated-teaser-rounded-reset'
              : 'teaser-picture-rounded-reset'
          "
        />
      </a>

      <div class="absolute top-4 left-4">
        <MediaTag :is-vplus="post.isVplus" :brand="post.brand || ''" />
      </div>

      <CategoryLinkLabel
        v-if="post.label?.text"
        class="absolute -bottom-3 left-4"
        :href="post.label?.link"
        :color="hasAssociatedPosts ? 'WHITE' : 'YELLOW'"
      >
        {{ post.label.text }}
      </CategoryLinkLabel>
    </div>

    <div
      class="px-4 md:px-0 pt-7 md:rounded-b"
      :class="{ 'bg-main-600 pb-4 md:px-4': hasAssociatedPosts }"
    >
      <ArticleInfo
        :title="post.title"
        :link="post.link"
        :comment-count="post.commentCount"
        :picture-count="post.pictureCount"
        :date="post.formatted_date ?? ''"
        :title-size="withBoldText ? 'XL' : 'M'"
        :informant-button="post.informantButton"
      />

      <div
        v-if="hasAssociatedPosts"
        class="flex flex-col gap-5 mt-10 md:grid md:grid-cols-2"
      >
        <XSmallTeaser
          v-for="ap in post.associated"
          :key="ap.id"
          :post="ap"
          label-color="WHITE"
        />
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
import CategoryLinkLabel from '../CategoryLinkLabel.vue'
import ResponsivePicture from '../ResponsivePicture.vue'
import ArticleInfo from './shared/ArticleInfo.vue'
import MediaTag from './shared/MediaTag.vue'
import XSmallTeaser from './XSmallTeaser.vue'

const props = defineProps<{
  post: Post
  withBoldText?: boolean
}>()

const hasAssociatedPosts = (props.post?.associated?.length ?? 0) > 0
</script>

<style>
.associated-teaser-rounded-reset img {
  @apply rounded-none md:rounded-t;
}
</style>
