<template>
  <ModalWrapper class="z-[5000101]" @click.self="emit('click')">
    <ModalBox class="overflow-hidden">
      <LoadingOverlay v-if="loading" />

      <h2 class="typo-teaser-3">Passwort ändern</h2>

      <form class="typo-body-1" @submit.prevent>
        <input
          v-model="currentPassword"
          type="password"
          class="w-full input-field-gray"
          :class="{ 'input-field-error': currentPasswordError }"
          placeholder="Aktuelles Passwort"
          @blur="validateCurrentPassword"
        />

        <ErrorMessage v-if="currentPasswordError">
          {{ currentPasswordError }}
        </ErrorMessage>

        <input
          v-model="newPassword"
          type="password"
          class="w-full input-field-gray"
          :class="{ 'input-field-error': newPasswordError }"
          placeholder="Neues Passwort"
          @blur="validateNewPassword"
        />

        <ErrorMessage v-if="newPasswordError">
          {{ newPasswordError }}
        </ErrorMessage>

        <input
          v-model="newPasswordAgain"
          type="password"
          class="w-full input-field-gray"
          :class="{ 'input-field-error': newPasswordAgainError }"
          placeholder="Wiederhole das neue Passwort"
          @blur="validateNewPasswordAgain"
        />

        <ErrorMessage v-if="newPasswordAgainError">
          {{ newPasswordAgainError }}
        </ErrorMessage>

        <ErrorMessage v-if="genericError">
          {{ genericError }}
        </ErrorMessage>

        <DefaultButton
          class="mt-8"
          :disabled="buttonDisabled"
          @click="changePassword"
        >
          Abschicken
        </DefaultButton>
      </form>
    </ModalBox>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import DefaultButton from '../DefaultButton.vue'
import ModalBox from '../ModalBox.vue'
import ModalWrapper from '../ModalWrapper.vue'
import ErrorMessage from './ErrorMessage.vue'
import LoadingOverlay from './LoadingOverlay.vue'

const props = withDefaults(
  defineProps<{
    errorMessage?: string
    disabled?: boolean
  }>(),
  {
    errorMessage: '',
    disabled: false,
  },
)

const emit = defineEmits<{
  (event: 'click'): void
  (
    event: 'submit',
    result: {
      currentPassword: string
      newPassword: string
      newPasswordAgain: string
    },
  ): void
}>()

const genericError = ref(props.errorMessage)
const currentPasswordError = ref('')
const newPasswordError = ref('')
const newPasswordAgainError = ref('')

const currentPassword = ref('')
const newPassword = ref('')
const newPasswordAgain = ref('')

const buttonDisabled = computed(
  () =>
    currentPassword.value === ''
    || newPassword.value === ''
    || newPasswordAgain.value === ''
    || props.disabled,
)
const loading = computed(() => props.disabled)

watch(
  () => props.errorMessage,
  () => {
    genericError.value = props.errorMessage
  },
)

function validateCurrentPassword() {
  if (currentPassword.value !== '') {
    return
  }

  currentPasswordError.value = 'Bitte gib dein aktuelles Passwort ein'
}

function validateNewPassword() {
  if (newPassword.value === '') {
    newPasswordError.value = 'Bitte gib ein neues Passwort ein'
    return
  }

  if (newPassword.value.length < 6) {
    newPasswordError.value = 'Bitte wähle ein Passwort mit mind. 6 Zeichen'
    return
  }

  if (/\s/.test(newPassword.value)) {
    newPasswordError.value = 'Das Passwort darf keine Leerzeichen enthalten'
  }
}

function validateNewPasswordAgain() {
  if (newPasswordAgain.value === '') {
    newPasswordAgainError.value = 'Bitte gib das neue Passwort noch einmal ein'
    return
  }

  if (newPassword.value !== newPasswordAgain.value) {
    newPasswordAgainError.value = 'Die Passwörter stimmen nicht überein'
  }
}

function changePassword() {
  currentPasswordError.value = ''
  newPasswordError.value = ''
  newPasswordAgainError.value = ''

  validateCurrentPassword()
  validateNewPassword()
  validateNewPasswordAgain()

  if (
    currentPasswordError.value !== ''
    || newPasswordError.value !== ''
    || newPasswordAgainError.value !== ''
  ) {
    return
  }

  emit('submit', {
    currentPassword: currentPassword.value,
    newPassword: newPassword.value,
    newPasswordAgain: newPasswordAgain.value,
  })
}
</script>
