import type { Ref } from 'vue'
import { ref } from 'vue'
import { appendCookieToContainer, deleteCookies, getCookie, splitUpCookie, ssoCookieName, ssoSessionCookieName, waitForCookieAsync } from '../utils/sso-cookie-helper'

const session: Ref<ISSOSession | null> = ref(null)

const defaultErrorMessage = 'Es ist ein technischer Fehler aufgetreten. Bitte versuche es in wenigen Minuten noch einmal.'
const localStorageName = 'vodl-user-status'

export function useSSO() {
  return {
    getSSOId: () => session.value?.id,
    getSSO: () => session.value,
    setSSOCookie: (sessionCookie: string, evoSSOCookie: string) => {
      if (!sessionCookie || !evoSSOCookie) {
        session.value = null
        return
      }

      const sessionCookieParts = splitUpCookie(sessionCookie)
      const evoSSOCookieParts = splitUpCookie(evoSSOCookie)

      session.value = {
        username: sessionCookieParts.username,
        fullname: sessionCookieParts.fullname,
        id: Number.parseInt(sessionCookieParts.id),
        email: sessionCookieParts.email,
        h: evoSSOCookieParts.h,
        t: evoSSOCookieParts.t,
      }
    },
    isLoggedIn: () => {
      return session.value !== null
    },
    areCookiesSet: () => {
      return Boolean(getCookie(ssoCookieName)) && Boolean(getCookie(ssoSessionCookieName))
    },
    getLocalUserStatus: () => {
      const userStatus = localStorage.getItem(localStorageName)
      return userStatus ? JSON.parse(userStatus) : null
    },
    login: async (username: string | number, password: string): Promise<any> => {
      if (!username || !password) {
        throw new Error('Username and password are required')
      }

      const response = await fetch('/wp-json/russmedia-sso/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ log: username, password }),
      })

      const result = await response.json()

      const loginResult = result.data?.login_result || {}
      const userData = result.data?.user_data || {}

      if (loginResult.error !== '0' || !userData?.user_id) {
        throw new Error(result.message ?? defaultErrorMessage)
      }

      session.value = {
        id: userData.user_id,
        lastname: userData.lastname ?? '',
        username: userData.login ?? '',
        email: userData.email ?? '',
        h: userData.hash ?? '',
        t: userData.token ?? '',
      }

      if (loginResult.cookie) {
        const lastImage = appendCookieToContainer(loginResult.cookie)

        if (!lastImage) {
          // Resolve but SSO cookie could not be set
          return result
        }

        await new Promise(resolve => lastImage.addEventListener('load', resolve))

        try {
          await waitForCookieAsync()
        }
        catch {
          // Resolve but SSO cookie could not be set
          return result
        }
      }

      // Login successful, return result
      return result
    },
    logout: async (): Promise<boolean> => {
      const response = await fetch('/wp-json/russmedia-sso/v1/logout', {
        method: 'POST',
      })

      session.value = null

      const result = await response.json()

      const cookie = result.data?.logout_result?.cookie ?? false

      // manually delete cookies if logout response does not contain cookie property
      if (!cookie) {
        deleteCookies()
        return true
      }

      const lastImage = appendCookieToContainer(cookie)

      if (!lastImage) {
        deleteCookies()
        return true
      }

      await new Promise(resolve => lastImage.addEventListener('load', resolve))
      return true
    },
    forgetPassword: async (username: string): Promise<any> => {
      const response = await fetch('/wp-json/russmedia-sso/v1/forget-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ log: username }),
      })

      const result = await response.json()

      result.sent = result.sent || false

      if (result.sent === false) {
        throw new Error(result.message ?? defaultErrorMessage)
      }

      result.message = result.message ?? ''
      return result
    },
    changePassword: async (currentPassword: string, newPassword: string, newPasswordAgain: string, hash: string, token: string): Promise<any> => {
      const response = await fetch(`/wp-json/russmedia-sso/v1/change-pw/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          old_password: currentPassword,
          new_password: newPassword,
          new_password_again: newPasswordAgain,
          hash,
          token,

        }),
      })

      const result = await response.json()

      if (result?.additional_data?.sso?.change_password_result?.success !== '1') {
        throw new Error(result?.additional_data?.sso?.user_error_text ?? defaultErrorMessage)
      }

      return result
    },
    resetPassword: async (newPassword: string, newPasswordAgain: string, hash: string): Promise<any> => {
      const response = await fetch(`/wp-json/russmedia-sso/v1/change-pw/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          new_password: newPassword,
          new_password_again: newPasswordAgain,
          hash,
        }),
      })

      const result = await response.json()

      if (result?.additional_data?.sso?.change_password_result?.success !== '1') {
        throw new Error(result?.additional_data?.sso?.user_error_text ?? defaultErrorMessage)
      }

      return result
    },
  }
}
