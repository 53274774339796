<template>
  <ModalWrapper
    class="z-[5000101]"
    data-testid="gift-article-status-modal"
    @click.self="emit('close')"
  >
    <ModalBox class="relative overflow-hidden md:w-auto md:min-w-96">
      <section class="flex flex-col items-center gap-12 pb-8 md:pb-6">
        <div class="flex items-center w-full pb-4 border-b-2 border-grau-500">
          <h1 class="ml-4 text-center grow typo-artikel-section-2">
            Artikel Teilen
          </h1>
          <CloseIcon class="w-4 h-4 cursor-pointer" @click="emit('close')" />
        </div>
        <SocialBookmarks
          :url="url"
          :text="text"
          :use-native-sharing="useNativeSharing"
          v-bind="$attrs"
        />
      </section>
    </ModalBox>
  </ModalWrapper>
</template>

<script setup lang="ts">
import CloseIcon from '../../../assets/svg/CloseBlack.svg'
import ModalBox from '../ModalBox.vue'
import ModalWrapper from '../ModalWrapper.vue'
import SocialBookmarks from './SocialBookmarks.vue'

withDefaults(
  defineProps<{
    url: string
    text: string
    clipboardTransition?: boolean
    bookmarks?: SocialBookmarkType[]
    useNativeSharing?: boolean
  }>(),
  {
    url: '',
    text: '',
    bookmarks: () => [
      'clipboard',
      'universal',
      'email',
      'facebook',
      'x',
      'whatsapp',
    ],
    useNativeSharing: true,
  },
)

const emit = defineEmits<{
  (event: 'close'): void
}>()
</script>
