declare global {
  interface Window {
    __cmp: any
    cmp_cdid: string
    cmp_disable_recall: boolean
    __tcfapi: any
  }
}

export default defineNuxtPlugin({
  name: 'cmp',
  dependsOn: ['auth'],
  parallel: true,
  setup() {
    const user = useAuth().getUser()
    const gdpr = useGdpr()

    // do not laod consent manager for PUR subscribers.
    if (user?.piano?.abo?.isPurSubscriber) {
      return
    }

    const config = useRuntimeConfig().public
    window.cmp_cdid = config.cmpCdid ?? ''
    window.cmp_disable_recall = true

    const { onLoaded } = useScript('/consentmanager.js', {
      trigger: 'onNuxtReady',
      tagPriority: 'high',
    })

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLElement && node.classList.contains('cmpbox')) {
              observer.disconnect()
              const vodlLoginLink = node.querySelector('.vodl-login') as HTMLElement
              if (vodlLoginLink) {
                vodlLoginLink.addEventListener('click', (event) => {
                  event.preventDefault()

                  const cmpBannerIds = ['#cmpbox', '#cmpbox2']
                  cmpBannerIds.forEach((id: string) => {
                    const cmp = document.querySelector(id)

                    if (cmp && cmp.parentNode) {
                      cmp.parentNode.removeChild(cmp)
                    }
                  })

                  document.body.style.overflow = ''

                  useModal().openModal('Login')
                })
              }
            }
          })
        }
      })
    })

    observer.observe(document.body, { childList: true, subtree: true })

    onLoaded(() => {
      window.__tcfapi('addEventListener', 2, (tcData: any, success: boolean) => {
        if (
          success
          && (tcData.eventStatus === 'tcloaded'
            || tcData.eventStatus === 'useractioncomplete')
        ) {
          observer.disconnect()
          consentGiven.accept()

          gdpr.acceptAllSettings()
          gdpr.setLocalTcfStatus(tcData)
        }
        else {
          gdpr.deleteLocalTcfStatus()
        }
      })
    })
  },
})
