declare global {
  interface Window {
    IOMm: any
    oewa_data: any
  }
}

export default defineNuxtPlugin({
  name: 'tracking',
  parallel: true,
  setup() {
    const { gtmId, oewaDomain, environment } = useRuntimeConfig().public

    if (environment !== 'production') {
      return
    }

    // Start GTM Tracking
    const { proxy } = useScriptGoogleTagManager({ id: gtmId, scriptOptions: { trigger: consentGiven } })
    useGtm().setInstance(proxy)

    // Start OEWA Tracking
    useHead({
      link: [
        {
          rel: 'preconnect',
          href: `https://${oewaDomain}/iomm/latest/manager/base/es6/bundle.js`,
          as: 'script',
          id: 'IOMmBundle',
          crossorigin: '',
        },
      ],
    })

    const { onLoaded } = useScript(`https://${oewaDomain}/iomm/latest/bootstrap/loader.js`, {
      tagPriority: 'high',
      trigger: consentGiven,
      bundle: true,
      use: () => window.IOMm,
    })

    onLoaded(() => {
      useOewa().trackInitial()
    })
  },
})
