import type { GdprConsent, GranularConsent } from '@russmedia-frontend/nordstern-embeds'

const gdprSettings = ref<GdprConsent>({
  necessary: false,
  preferences: false,
  marketing: false,
  statistics: false,
  granular: {} as { [key in GranularConsent]?: string | boolean },
})

export function useGdpr() {
  return {
    getSettings: () => gdprSettings,
    setSettings,
    acceptAllSettings,
    updateGranularConsent,
    updateEmbedProvider,
    setLocalTcfStatus,
    getLocalTcfStatus,
    deleteLocalTcfStatus,
  }
}

/**
 * Set the gdpr settings
 *
 * @param newSettings - The new gdpr settings
 */
function setSettings(newSettings: GdprConsent) {
  gdprSettings.value = newSettings
}

/**
 * Update the granular consent settings
 *
 * @param embedProviderConfig - The new granular consent settings
 */
function updateGranularConsent(embedProviderConfig: { [key in GranularConsent]?: boolean }) {
  gdprSettings.value.granular = embedProviderConfig
}

/**
 * Accept all gdpr categories
 */
function acceptAllSettings() {
  gdprSettings.value.necessary = true
  gdprSettings.value.preferences = true
  gdprSettings.value.marketing = true
  gdprSettings.value.statistics = true
}

/**
 * Update the embed provider consent and save to pur subscription settings
 * if user is a pur subscriber
 *
 * @param provider - The embed provider to update
 * @param value - The new consent status
 */
async function updateEmbedProvider(provider: string, value: boolean = true) {
  if (!gdprSettings.value.granular) {
    gdprSettings.value.granular = {}
  }

  if (provider === '*') {
    // Map over all providers and set them to true
    Object.keys(gdprSettings.value.granular || {}).forEach((key) => {
      gdprSettings.value.granular![key as keyof typeof gdprSettings.value.granular] = value
    })
  }
  else {
    gdprSettings.value.granular[provider as keyof typeof gdprSettings.value.granular] = value
  }
}

/**
 * Set the local tcf status recived from the tcf api
 *
 * @param tcfStatus The tcf status
 */
function setLocalTcfStatus(tcfStatus: any) {
  if (import.meta.client) {
    localStorage.setItem('vodl-tcf-status', JSON.stringify(tcfStatus))
  }
}

/**
 * Get the local tcf status
 *
 * @returns The local tcf status
 */
function getLocalTcfStatus(): any {
  if (import.meta.client) {
    const tcfStatus = localStorage.getItem('vodl-tcf-status')
    return tcfStatus ? JSON.parse(tcfStatus) : null
  }

  return null
}

/**
 * Delete the local tcf status
 */
function deleteLocalTcfStatus() {
  if (import.meta.client) {
    localStorage.removeItem('vodl-tcf-status')
  }
}
