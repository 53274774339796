<template>
  <div
    data-testid="article-info"
    class="flex flex-col"
    :class="titleSize === 'S' || titleSize === 'XS' ? 'gap-2' : 'gap-4'"
  >
    <h2
      class="pb-1 line-clamp-3 overflow-ellipsis"
      :class="[textVariants, { 'text-white': color === 'WHITE' }]"
    >
      <a
        :href="link"
        :class="{ 'yellow-underline': color === 'BLACK' && titleUnderline }"
      >
        {{ title }}
      </a>
    </h2>

    <slot />

    <div
      class="flex items-center gap-4 leading-none typo-meta-3-bold"
      :class="{
        'text-white': color === 'WHITE',
        'hidden md:flex': titleSize === 'S' || titleSize === 'XS',
      }"
    >
      <span
        v-if="commentCount && commentCount > 0"
        class="flex items-center gap-1"
      >
        <Comment />

        {{
          commentCount === 1
            ? `${commentCount} Kommentar`
            : `${commentCount} Kommentare`
        }}
      </span>

      <span
        v-if="showPictureCount && pictureCount && pictureCount > 1"
        class="flex items-center gap-1"
      >
        <WhitePicture v-if="color === 'WHITE'" />

        <Picture v-else />

        {{ pictureCount }} Bilder
      </span>

      <span>{{ date }}</span>
    </div>

    <div v-if="informantButton">
      <InformantButton :link="informantButton.link" :text="informantButton.text" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Comment from '../../../../assets/svg/Comment.svg'
import Picture from '../../../../assets/svg/Picture.svg'
import WhitePicture from '../../../../assets/svg/WhitePicture.svg'
import InformantButton from '../../InformantButton.vue'

const props = withDefaults(
  defineProps<{
    title: string
    link: string
    commentCount?: number
    pictureCount?: number
    date: string
    color?: 'BLACK' | 'WHITE'
    titleSize?: 'XL' | 'L' | 'M' | 'S' | 'XS'
    showPictureCount?: boolean
    titleUnderline?: boolean
    informantButton?: {
      text: string
      link: string
    }
  }>(),
  {
    color: 'BLACK',
    titleSize: 'M',
    showPictureCount: true,
    titleUnderline: false,
  },
)

const titleSizes = {
  XL: 'typo-teaser-1',
  L: 'typo-teaser-2',
  M: 'typo-teaser-3',
  S: 'typo-teaser-4',
  XS: 'typo-teaser-5',
}

const textVariants = computed(() => {
  return titleSizes[props.titleSize]
})
</script>

<style scoped>
.yellow-underline {
  @apply inline decoration-clone bg-no-repeat text-text bg-gradient-to-b from-main-600 to-main-600;
  background-position-y: 88%;
  background-size: 100% 8px;

  @media (min-width: 48rem) {
    background-size: 100% 12px;
  }
}
</style>
