<template>
  <div
    class="relative w-full h-full"
    data-testid="card"
    :class="{
      'aspect-[3/2]': ratio === 'small' || ratio === undefined,
      'aspect-[9/16]': ratio === 'portrait',
    }"
  >
    <div
      class="absolute bottom-0 left-0 z-10 w-full rounded h-2/5 opacity-80 bg-gradient-to-t from-black to-transparent"
      :class="{ 'rounded-2xl': ratio === 'portrait' }"
    />
    <a
      class="block w-full h-full overflow-hidden rounded hover:cursor-pointer"
      :class="{ 'rounded-2xl': ratio === 'portrait' }"
      :href="item.link"
      @click.prevent="emit('cardClicked', item)"
    >
      <div
        v-if="item.formattedDateTime"
        class="absolute z-10 flex left-2 right-2 top-4"
      >
        <div class="flex items-center gap-1 px-2 py-1 mx-auto bg-black rounded bg-opacity-70">
          <PlayButton class="w-3 h-3" />
          <span class="text-white typo-label">
            {{
              item.formattedDuration
                ? `${item.formattedDuration} - ${item.formattedDateTime}`
                : item.formattedDateTime
            }}
          </span>
        </div>
      </div>
      <img
        v-if="item.image.src"
        loading="lazy"
        class="w-full h-full transition-all duration-300 ease-in-out rounded hover:scale-105"
        :class="{ 'rounded-2xl': ratio === 'portrait' }"
        :src="item.image.src"
        :alt="item.image.alt"
      />
      <div v-if="item.title" class="absolute bottom-0 z-10 px-6 py-4">
        <h3 class="w-full text-center text-white typo-teaser-4 line-clamp-2">
          {{ item.title }}
        </h3>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
import PlayButton from '../../assets/svg/PlayButton.svg'

withDefaults(
  defineProps<{
    item: CardItem
    ratio?: 'small' | 'portrait'
  }>(),
  {
    ratio: 'small',
  },
)

const emit = defineEmits<{
  (event: 'cardClicked', item: CardItem): void
}>()
</script>
