<template>
  <div v-if="item.id && item.accountId" data-testid="brightcove-player">
    <div class="relative w-full">
      <div class="relative w-full aspect-video">
        <video-js
          ref="video"
          class="w-full h-full md:flex-1"
          :data-account="item.accountId"
          :data-player="item.playerId"
          :data-embed="item.embedId"
          controls=""
          :data-video-id="!item.isPlaylist ? item.id : ''"
          :data-playlist-id="item.isPlaylist ? item.id : ''"
          :data-ad-config-id="item.adConfigId"
          data-application-id=""
        />
      </div>
    </div>
    <div
      v-if="detailed"
      data-test-id="bc-detail-info"
      class="flex flex-col p-4 md:flex-1 md:px-0 md:py-4"
    >
      <h1 v-if="item.title" class="pb-4 text-lg font-black break-all">
        {{ item.title }}
      </h1>
      <span v-if="item.description" class="pb-4 text-base">
        {{ item.description }}
      </span>
      <span v-if="item.formattedDateTime" class="pb-4 text-sm opacity-60">
        test
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useScriptTag } from '@vueuse/core'
import { onBeforeUnmount, ref } from 'vue'

interface ExtWindow extends Window {
  videojs: any
}

const props = withDefaults(
  defineProps<{
    item: BrightcovePlayerItem
    detailed?: boolean
  }>(),
  {
    item: () => ({
      accountId: '',
      id: '',
      playerId: 'default',
      isPlaylist: false,
      embedId: '',
      adConfigId: '',
      title: '',
      description: '',
      formattedDateTime: '',
    }),
    detailed: false,
  },
)

const emit = defineEmits<{
  (event: 'play'): void
  (event: 'ended'): void
}>()

declare let window: ExtWindow

useScriptTag(
  `https://players.brightcove.net/6292321027001/${props.item.playerId}_default/index.min.js`,
  () => {
    initPlayer()
  },
)

const video = ref(null as null | HTMLElement)
const player = ref({} as any)

function onPlay() {
  emit('play')
}

function onEnded() {
  emit('ended')
}

async function initPlayer() {
  if (!props.item.id || !props.item.accountId) {
    return
  }

  if (!window.videojs) {
    return
  }

  player.value = window.videojs.getPlayer(video.value) || {}

  if (typeof player.value.on !== 'function') {
    return
  }

  player.value.on('play', onPlay)
  player.value.on('ended', onEnded)
}

onBeforeUnmount(() => {
  if (!player.value || typeof player.value.dispose !== 'function') {
    return
  }

  player.value.dispose()
})
</script>
