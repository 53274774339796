<template>
  <button
    title="Clipboard"
    type="button"
    class="flex flex-col items-center gap-2"
    data-testid="clipboard"
    @click="share"
  >
    <ClipboardIcon />
    <span class="typo-meta-1">Link kopieren</span>
  </button>
</template>

<script setup lang="ts">
import ClipboardIcon from '../../../assets/svg/Clipboard.svg'

const props = defineProps<{
  url: string
  text?: string
}>()

const emit = defineEmits<{
  (event: 'buttonClicked', title: string): void
}>()

async function share() {
  if (typeof navigator.clipboard?.writeText !== 'function') {
    emit('buttonClicked', 'clipboard')
    return
  }

  await navigator.clipboard.writeText(props.url)

  emit('buttonClicked', 'clipboard')
}
</script>
