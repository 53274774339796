import { useModal } from '../composables/useModal'

export default defineNuxtPlugin({
  name: 'eventHooks',
  parallel: true,
  setup() {
    const modal = useModal()

    window.addEventListener('open_login_form', () => {
      modal.openModal('Login')
    })
  },
})
