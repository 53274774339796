<template>
  <div class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black z-[5000101]">
    <slot name="close-button">
      <Close
        class="absolute z-20 text-white cursor-pointer right-8 top-8"
        @click="emit('close')"
      />
    </slot>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue'
import Close from '../../assets/svg/Close.svg'

const emit = defineEmits<{
  (event: 'close'): void
}>()

onMounted(() => {
  document.body.classList.add('overflow-y-hidden')
})

onBeforeUnmount(() => {
  document.body.classList.remove('overflow-y-hidden')
})
</script>
